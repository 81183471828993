/**
 * Module dependencies.
 */

import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from 'app/components/pages/home/home';
import { appRoutes } from 'app/routes';
import React, { useMemo } from 'react';
import { SkinDashboard } from './components/templates/dashboard/skin-dashboard';
import { DashboardOverview } from './components/pages/dashboard/overview/dashboard-overview';
import { MovementsOverview } from './components/pages/bank-accounts/overview/movements-overview';
import { SkinSettings } from './components/templates/dashboard/skin-settings';
import { SettingsOverview } from './components/pages/settings/overview/settings-overview';
import { SettingsProfile } from './components/pages/settings/profile/settings-profile';
import { useTranslation } from 'react-i18next';
import { listCrudRoutes } from './components/organisms/crud/utils';
import { createBudgetsTemplate } from './components/pages/bank-accounts/budgets/form-config';
import { createCategoriesTemplate } from './components/pages/bank-accounts/categories/form-config';
import { createSubCategoriesTemplate } from './components/pages/bank-accounts/sub-categories/form-config';
import { createBankAccountsTemplate } from './components/pages/bank-accounts/bank-accounts/form-config';
import { BankAccountConnect } from './components/pages/bank-accounts/bank-accounts/bank-account-connect';
import { BankAccountSync } from './components/pages/bank-accounts/bank-accounts/bank-account-connect/bank-account-sync';
import { createTransactionsTemplate } from './components/pages/bank-accounts/movements/movements-config';
import { SkinProjects } from './components/templates/dashboard/skin-projects';
import { ProjectsOverview } from './components/pages/projects/overview/projects-overview';
import { AddNewProject } from './components/pages/projects/overview/add-new-project';
import { Project } from './components/pages/projects/project/project';
import { ProjectSettings } from './components/pages/projects/project/settings/project-settings';
import { createProjectSuppliersTemplate } from './components/pages/projects/project/suppliers/form-config';
import { createProjectSuppliersContactsTemplate } from './components/pages/projects/project/suppliers/form-contacts-config';
import { createProjectBudgetsTemplate } from './components/pages/projects/project/budgets/form-config';
import { createProjectProposalsTemplate } from './components/pages/projects/project/proposals/form-config';
import { createProjectMovementsTemplate } from './components/pages/projects/project/movements/form-config';
import { createProjectPaymentTypesTemplate } from './components/pages/projects/project/payment-types/form-config';
import { SkinBankAccounts } from './components/templates/dashboard/skin-bank-accounts';
import { createExtendedProposalsTemplate } from './components/pages/projects/project/budgets/form-proposals-config';
import { SkinAppsCrypto } from './components/templates/dashboard/skin-apps-crypto';
import { AppsCryptoOverview } from './components/pages/apps-crypto/overview/apps-crypto-overview';
import { createAppsCryptoProvidersTemplate } from './components/pages/apps-crypto/providers/form-config';
import { createAppsCryptoTransactionsTemplate } from './components/pages/apps-crypto/transactions/form-config';
import { TradesList } from './components/pages/apps-crypto/trades/list/trades-list';
import { DashboardHeader } from './components/templates/dashboard/dashboard-header';

/**
 * Export `AppRoutes` component.
 */

export function AppRoutes(): JSX.Element {
  const [translate] = useTranslation();
  const budgetsTemplate = useMemo(() => createBudgetsTemplate(translate), [translate]);
  const categoriesTemplate = useMemo(() => createCategoriesTemplate(translate), [translate]);
  const subCategoriesTemplate = useMemo(() => createSubCategoriesTemplate(translate), [translate]);
  const bankAccountsTemplate = useMemo(() => createBankAccountsTemplate(translate), [translate]);
  const transactionsTemplate = useMemo(() => createTransactionsTemplate(translate), [translate]);
  const projectSuppliersContactsTemplate = useMemo(
    () => createProjectSuppliersContactsTemplate(translate),
    [translate]
  );

  const projectSuppliersTemplate = useMemo(
    () => createProjectSuppliersTemplate(translate, projectSuppliersContactsTemplate),
    [projectSuppliersContactsTemplate, translate]
  );

  const projectProposalsTemplate = useMemo(() => createProjectProposalsTemplate(translate), [translate]);

  const extendedProposalsTemplate = useMemo(() => {
    return createExtendedProposalsTemplate(translate);
  }, [translate]);

  const projectBudgetsTemplate = useMemo(
    () => createProjectBudgetsTemplate(translate, extendedProposalsTemplate),
    [extendedProposalsTemplate, translate]
  );

  const projectMovementsTemplate = useMemo(() => createProjectMovementsTemplate(translate), [translate]);
  const projectPaymentTypesTemplate = useMemo(() => createProjectPaymentTypesTemplate(translate), [translate]);

  // Apps Crypto
  const appsCryptoProvidersTemplate = useMemo(() => createAppsCryptoProvidersTemplate(translate), [translate]);
  const appsCryptoTransactionsTemplate = useMemo(() => createAppsCryptoTransactionsTemplate(translate), [translate]);

  return (
    <Routes>
      <Route Component={() => <Home />} path={appRoutes.home} />

      <Route Component={() => <DashboardHeader />} path={appRoutes.dashboard.base}>
        <Route Component={() => <SkinDashboard />} path={appRoutes.dashboard.home.base}>
          <Route Component={() => <DashboardOverview />} index />
        </Route>

        <Route Component={() => <SkinBankAccounts />} path={appRoutes.dashboard.bankAccounts.base}>
          <Route Component={() => <MovementsOverview />} index />
          <Route Component={() => <BankAccountConnect />} path={appRoutes.dashboard.bankAccounts.accounts.connect} />
          <Route Component={() => <BankAccountSync />} path={appRoutes.dashboard.bankAccounts.accounts.sync} />

          {listCrudRoutes(bankAccountsTemplate)}

          {listCrudRoutes(categoriesTemplate)}

          {listCrudRoutes(subCategoriesTemplate)}

          {listCrudRoutes(budgetsTemplate)}

          {listCrudRoutes(transactionsTemplate)}
        </Route>

        <Route Component={() => <SkinProjects />} path={appRoutes.dashboard.projects.base}>
          <Route Component={() => <ProjectsOverview />} index />
          <Route Component={() => <AddNewProject />} path={appRoutes.dashboard.projects.addNewProject} />
          <Route Component={() => <Project />} path={appRoutes.dashboard.projects.project.overview} />

          {listCrudRoutes(projectSuppliersTemplate)}

          {listCrudRoutes(projectSuppliersContactsTemplate)}

          {listCrudRoutes(projectBudgetsTemplate)}

          {listCrudRoutes(projectProposalsTemplate)}

          {listCrudRoutes(projectMovementsTemplate)}

          {listCrudRoutes(projectPaymentTypesTemplate)}

          <Route Component={() => <ProjectSettings />} path={appRoutes.dashboard.projects.project.settings} />
        </Route>

        <Route Component={() => <SkinAppsCrypto />} path={appRoutes.dashboard.appsCrypto.base}>
          <Route Component={() => <AppsCryptoOverview />} index />

          {listCrudRoutes(appsCryptoProvidersTemplate)}

          {listCrudRoutes(appsCryptoTransactionsTemplate)}

          <Route Component={() => <TradesList />} path={appRoutes.dashboard.appsCrypto.trades} />
        </Route>

        <Route Component={() => <SkinSettings />} path={appRoutes.dashboard.settings.base}>
          <Route Component={() => <SettingsOverview />} index />
          <Route Component={() => <SettingsProfile />} path={appRoutes.dashboard.settings.profile} />
        </Route>
      </Route>

      <Route Component={() => <Navigate to={appRoutes.home} />} path={'*'} />
    </Routes>
  );
}
