/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import moment from 'moment';
import React from 'react';
import { toLower } from 'lodash';
import { Icon } from './widgets.styles';
import BigNumber from 'bignumber.js';
import { TFunction } from 'react-i18next';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { Provider, Transaction } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';
import { get, omit } from 'lodash';
import axios from 'axios';
import { OptionTransactionLabel } from '../overview/components/option-transaction-label';
import { LinkedStatusTransaction } from '../overview/components/linked-status-transaction';

/**
 * Currencies.
 */

const currencies = (translate: TFunction, type: 'add' | 'edit') => ({
  name: 'currency',
  disabled: type === 'edit',
  rules: requiredRule(translate),
  options: [
    {
      value: 'usdt',
      label: 'USDT'
    },
    {
      value: 'eur',
      label: 'EUR'
    }
  ]
});

/**
 * Format currency crypto.
 */

export function formatNumber(value: number | string | undefined, currency?: string): string | null {
  if (!value) {
    return null;
  }

  const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(numericValue)) {
    return null;
  }

  const decimalPlaces = Math.abs(numericValue) > 10 ? 2 : 8;
  const formattedValue = numericValue.toFixed(decimalPlaces);

  return currency ? `${formattedValue} ${currency}` : formattedValue;
}

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: translate('common.table.columns.coin'),
    dataIndex: 'coin',
    style: { justifyContent: 'flex-start' },
    key: 'coin',
    size: '200px',
    render: item => (
      <>
        <Icon src={`/api/crypto/icon/${toLower(item.coin)}`} /> {item.coin}
      </>
    )
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    style: { justifyContent: 'flex-end' },
    key: 'amount',
    render: item => item.amount
  },
  {
    title: translate('common.table.columns.unitPrice'),
    dataIndex: 'unitPrice',
    style: { justifyContent: 'flex-end' },
    key: 'unitPrice',
    render: item => formatNumber(item.unitPrice, '$')
  },
  {
    title: translate('common.table.columns.total'),
    dataIndex: 'total',
    style: { justifyContent: 'flex-end' },
    key: 'total',
    render: item => formatNumber(item.total, '$')
  },
  {
    title: translate('common.table.columns.type'),
    dataIndex: 'type',
    style: { justifyContent: 'flex-start' },
    size: '1fr',
    key: 'type'
  },
  {
    title: translate('common.table.columns.provider'),
    dataIndex: 'provider.name',
    style: { justifyContent: 'flex-start' },
    size: '1fr',
    key: 'providerId'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    style: { justifyContent: 'flex-start' },
    key: 'createdAt',
    render: item => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    style: { justifyContent: 'flex-start' },
    key: 'updatedAt',
    render: item => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction, type: 'add' | 'edit'): FormList => [
  {
    type: 'inputField',
    name: 'coin',
    label: translate('common.labels.coin'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputCurrencyField',
    name: 'amount',
    addonAfter: form => {
      return form.getValues('coin');
    },
    onChange: (value, form) => {
      let total = form.getValues('total');

      if (new BigNumber(value).isLessThan('0') && new BigNumber(total).isGreaterThan('0')) {
        form.setValue('total', new BigNumber(total).multipliedBy(-1).toString());
      }

      if (new BigNumber(value).isGreaterThan('0') && new BigNumber(total).isLessThan('0')) {
        form.setValue('total', new BigNumber(total).multipliedBy(-1).toString());
      }

      total = form.getValues('total');

      if (!total) {
        form.setValue('unitPrice', '');

        return;
      }

      if (total) {
        form.setValue('unitPrice', new BigNumber(total).dividedBy(value).toString());
      }
    },
    label: translate('common.labels.amount'),
    rules: requiredRule(translate)
  },
  {
    type: 'if',
    name: 'killTransactions_2',
    watchFields: ['isAirdrop'],
    condition: ([isAirdrop]: any) => !isAirdrop,
    thenIf: [
      {
        type: 'inputCurrencyField',
        name: 'unitPrice',
        currencies: currencies(translate, type),
        onChange: (value, form) => {
          const amount = form.getValues('amount');

          console.log(value, amount);

          if (!value) {
            form.setValue('total', '');

            return;
          }

          if (amount) {
            form.resetField('total');
            form.setValue('total', new BigNumber(value).multipliedBy(amount).toString());
          }
        },
        label: translate('common.labels.unitPrice'),
        rules: requiredRule(translate)
      },
      {
        type: 'inputCurrencyField',
        currencies: currencies(translate, type),
        name: 'total',
        onChange: (value, form) => {
          const amount = form.getValues('amount');

          if (!value) {
            form.setValue('unitPrice', '');
            form.resetField('unitPrice');

            return;
          }

          if (amount) {
            form.resetField('unitPrice');
            form.setValue('unitPrice', new BigNumber(value).dividedBy(amount).toString());
          }
        },
        label: translate('common.labels.total'),
        rules: requiredRule(translate)
      }
    ]
  },
  {
    type: 'checkBoxField',
    name: 'isAirdrop',
    label: translate('common.labels.airdrop'),
    rules: undefined
  },
  {
    type: 'if',
    name: 'killTransactions_3',
    watchFields: ['total'],
    condition: ([total]: any) => new BigNumber(total).isGreaterThan(0),
    thenIf: [
      {
        type: 'selectField',
        name: 'type',
        label: translate('common.labels.type'),
        rules: requiredRule(translate),
        options: [
          {
            label: translate('common.labels.trade'),
            value: 'trade'
          },
          {
            label: translate('common.labels.launchpool'),
            value: 'launchpool'
          },
          {
            label: translate('common.labels.accumulation'),
            value: 'accumulation'
          }
        ]
      }
    ]
  },
  {
    type: 'selectField',
    name: 'providerId',
    label: translate('common.labels.provider'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['providers'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.appsCryptoProviders
          }
        ];
      },
      normalize: (items: Provider[]) => {
        return items?.map(item => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },
  {
    type: 'datePickerField',
    name: 'createdAt',
    style: { width: '100%' },
    label: translate('common.labels.movementDate'),
    rules: undefined
  },
  {
    type: 'if',
    name: 'killTransactions',
    watchFields: ['total'],
    condition: ([total]: any) => new BigNumber(total).isLessThan(0),
    thenIf: [
      {
        type: 'render',
        name: 'render',
        renderComponent: LinkedStatusTransaction
      },
      {
        type: 'selectField',
        name: 'linked',
        mode: 'multiple',
        label: translate('common.labels.linkedTransactions'),
        rules: undefined,
        options: {
          hook: useCrudRequest as any,
          hookProps: (values: any, params: any) => {
            return [
              {
                key: ['transactions'],
                options: {
                  interpolations: params,
                  params: {
                    providerId: get(values, ['providerId']),
                    coin: get(values, ['coin'])
                  }
                },
                endpoint: apiEndpoints.appsCryptoTransactions
              }
            ];
          },
          normalize: (items: Transaction[]) => {
            return items
              ?.filter(item => new BigNumber(item.amount).isGreaterThan(0))
              .map(item => ({
                value: item.id,
                label: <OptionTransactionLabel item={item} />
              }));
          }
        }
      }
    ]
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate, 'add'),
  normalizeInitialValues: () => ({
    currency: 'usdt'
  }),
  normalizePayload: async values => {
    const { data } = await axios.get(`/api/crypto/prices/${values.currency}/USDT`);

    const result = {
      ...omit(values, ['currency']),
      amount: new BigNumber(values.amount).toString(),
      total: new BigNumber(values.total).multipliedBy(data).toString(),
      unitPrice: new BigNumber(values.unitPrice).multipliedBy(data).toString(),
      createdAt: values.createdAt ? moment(values.createdAt).format('YYYY-MM-DD HH:mm:ss') : undefined
    };

    return result;
  }
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate, 'edit'),
  normalizeInitialValues: (keys, data) => {
    const result: any = {
      ...omit(data, [
        'provider',
        'id',
        'updatedAt',
        'userId',
        'closedTo',
        'closedBy',
        'currentTotal',
        'currentStatus',
        'currentProfitPercentage',
        'currentProfit',
        'currentType'
      ]),
      currency: 'usd'
    };

    result.linked = data?.linked?.map((item: any) => item.id);

    return result;
  },
  normalizePayload: async values => {
    const result = {
      ...omit(values, ['currency', 'closedUnitPrice', 'closedTotal']),
      total: !!values.isAirdrop ? '0' : new BigNumber(values.total).toString(),
      isAirdrop: !!values.isAirdrop,
      amount: new BigNumber(values.amount).toString(),
      unitPrice: !!values.isAirdrop ? '0' : new BigNumber(values.unitPrice).toString(),
      createdAt: values.createdAt ? moment(values.createdAt).format('YYYY-MM-DD HH:mm:ss') : undefined
    };

    console.log('>>', result);

    return result;
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.appsCrypto.transactions,
  key: ['categories'],
  normalizeFilters: (data: any) => {
    const { ...filters } = data.filters;
    const result: any = { filters, order: [] };

    result.order.push({ key: 'createdAt', direction: 'desc' });

    return result;
  },
  endpoint: apiEndpoints.appsCryptoTransactions,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createAppsCryptoTransactionsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
