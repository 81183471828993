/**
 * Module dependencies.
 */

import { Button, Input, Modal } from 'antd';
import { Trade } from 'app/hooks/requests/apps-crypto/use-get-trades';
import { useGetTransactions } from 'app/hooks/requests/apps-crypto/use-get-transactions';
import React, { useCallback, useEffect, useState } from 'react';
import { Cell, GridTable, HeaderCell, HeaderRow, Row } from '../../overview/overview.styles';
import { Icon } from '../../transactions/widgets.styles';
import { toLower } from 'lodash';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useAddTransactionToTrade } from 'app/hooks/requests/apps-crypto/use-add-transaction-to-trade';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: Trade;
};

/**
 * Selected
 */

function selected(obj: any, item: Trade) {
  return item?.transactions?.find(item => item.id === obj.id);
}

/**
 * Export `AddTransactions` component.
 */

export function AddTransactions({ item, isOpen, onClose }: Props): JSX.Element {
  const [search, setSearch] = useState('');
  const { data } = useGetTransactions(search);
  const { mutateAsync: addTransaction } = useAddTransactionToTrade();
  const handleAdd = useCallback(
    (obj: any) => {
      return addTransaction({
        tradeId: item.id,
        transactionId: obj.id,
        type: 'add'
      });
    },
    [addTransaction, item.id]
  );

  const handleRemove = useCallback(
    (obj: any) => {
      return addTransaction({
        tradeId: item.id,
        transactionId: obj.id,
        type: 'remove'
      });
    },
    [addTransaction, item.id]
  );

  useEffect(() => {
    function escFunction(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keyup', escFunction, false);

    return () => {
      document.removeEventListener('keyup', escFunction, false);
    };
  }, [onClose]);

  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      centered
      onCancel={undefined}
      onOk={onClose}
      open={isOpen}
      title={'Add Transactions'}
      width={1400}
    >
      <Input
        onChange={(value: any) => {
          console.log(value.target.value);
          setSearch(value.target.value);
        }}
        value={search}
      />

      <GridTable columns={10}>
        <HeaderRow>
          <HeaderCell>{'ID'}</HeaderCell>
          <HeaderCell>{'Coin'}</HeaderCell>
          <HeaderCell>{'Amount'}</HeaderCell>
          <HeaderCell>{'Unit Price'}</HeaderCell>
          <HeaderCell>{'Total'}</HeaderCell>
          <HeaderCell>{'Type'}</HeaderCell>
          <HeaderCell>{'Provider'}</HeaderCell>
          <HeaderCell>{'Created At'}</HeaderCell>
          <HeaderCell>{'Updated At'}</HeaderCell>
          <HeaderCell>{'Actions'}</HeaderCell>
        </HeaderRow>

        {(data ?? []).map(obj => {
          const isSelected = selected(obj, item);
          const style = !isSelected ? {} : { backgroundColor: 'rgba(0, 255, 0, 0.1)' };

          return (
            <Row key={obj.id}>
              <Cell style={style}>{obj.id}</Cell>
              <Cell style={style}>
                <Icon src={`/api/crypto/icon/${toLower(obj.coin)}`} /> {obj.coin}
              </Cell>
              <Cell style={style}>{obj.amount}</Cell>
              <Cell style={style}>{obj.unitPrice}</Cell>
              <Cell style={style}>{obj.total}</Cell>
              <Cell style={style}>{obj.type}</Cell>
              <Cell style={style}>{obj.provider?.name}</Cell>
              <Cell style={style}>{obj.createdAt}</Cell>
              <Cell style={style}>{obj.updatedAt}</Cell>
              <Cell style={{ ...style, gap: 8 }}>
                {!isSelected ? (
                  <Button icon={<PlusCircleOutlined />} onClick={() => handleAdd(obj)} size={'small'} />
                ) : (
                  <Button icon={<MinusCircleOutlined />} onClick={() => handleRemove(obj)} size={'small'} />
                )}
              </Cell>
            </Row>
          );
        })}
      </GridTable>
    </Modal>
  );
}
