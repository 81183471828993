/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useGetTradesInvalidator } from './use-get-trades';

/**
 * `Payload` type.
 */

type Payload = {
  id: number;
  payload: any;
};

/**
 * Edit trade.
 */

async function editTrade(client: AxiosInstance, id: number, data: any): Promise<void> {
  await client({
    method: 'patch',
    data,
    url: resolveAppUrl('appsCryptoTradeEdit', { interpolations: { id } })
  });
}

/**
 * Export `useEditTrade`.
 */

export function useEditTrade() {
  const client = useRequestProvider();
  const invalidator = useGetTradesInvalidator();

  return useMutation({
    mutationFn: async ({ id, payload }: Payload) => {
      const result = await editTrade(client, id, payload);

      await invalidator();

      return result;
    },
    onSuccess: () => {
      // Nothing...
    }
  });
}
