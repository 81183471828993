/**
 * Module dependencies.
 */

import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';

/**
 * Export `GridTable` styled component.
 */

export const GridTable = styled.div<{
  columns?: number;
  gridTemplate?: string;
}>`
  display: grid;
  width: 100%;
  gap: 2px;
  grid-template-columns: repeat(${props => props.columns}, auto);

  ${ifProp<any, any>(
    'gridTemplate',
    css`
      grid-template-columns: ${(props: any) => props.gridTemplate};
    `
  )}
`;

/**
 * Export `HeaderTable` styled component.
 */

export const HeaderTable = styled(GridTable)`
  background-color: #eee;
  margin-bottom: 32px;
`;

/**
 * Export `SpaceBetween` styled component.
 */

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

/**
 * Export `HeaderCell` styled component.
 */

export const HeaderCell = styled.div<{
  sticky?: number;
  width?: number;
}>`
  background-color: #ddd;
  padding: 8px 0;
  text-align: center;
  font-weight: 600;
  min-width: ${(props: any) => (!!props.width ? `${props.width}px` : 'auto')};

  ${ifProp<any, any>(
    'sticky',
    css`
      position: sticky;
      top: ${(props: any) => props.sticky}px;
    `
  )}
`;

/**
 * Export `HeaderRow` styled component.
 */

export const HeaderRow = styled.div`
  display: contents;
`;

/**
 * Export `BadgeContainer` styled component.
 */

export const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

/**
 * Export `Cell` styled component.
 */

export const Cell = styled.div<{
  right?: boolean;
  center?: boolean;
  isNegative?: boolean;
  isPositive?: boolean;
  type?: 'running' | 'airdrop' | 'closedWin' | 'closedLoss' | 'idle' | 'finished';
  isNegative0Funds?: boolean;
  isAirDrop?: boolean;
  span?: number;
  collapse?: number;
  closed?: boolean;
  summary?: boolean;
  width?: number;
}>`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  cursor: pointer;
  min-width: ${(props: any) => (!!props.width ? `${props.width}px` : 'auto')};

  ${ifProp<any, any>(
    'collapse',
    css`
      grid-column: span ${(props: any) => props.collapse};
      padding: 0;
      border-bottom: initial;
    `
  )}

  ${ifProp<any, any>(
    'span',
    css`
      grid-column: span ${(props: any) => props.span};
    `
  )}

  ${ifProp<any, any>(
    'summary',
    css`
      margin-top: 16px;
    `
  )}

  ${ifProp<any, any>(
    'right',
    css`
      justify-content: flex-end;
    `
  )}

  ${ifProp<any, any>(
    'center',
    css`
      justify-content: center;
    `
  )}

  ${ifProp<any, any>(
    'closed',
    css`
      opacity: 0.5;
    `
  )}

  ${ifProp<any, any>(
    'isNegative',
    css`
      background-color: rgba(255, 0, 0, 0.1);
    `
  )}

  ${ifProp<any, any>(
    'isPositive',
    css`
      background-color: rgba(0, 255, 0, 0.1);
    `
  )}

  ${switchProp('type', {
    airdrop: css`
      background-color: rgba(0, 0, 255, 0.1);
    `,
    closedWin: css`
      background-color: rgba(0, 100, 0, 0.2);
    `,
    closedLoss: css`
      background-color: rgba(100, 0, 0, 0.2);
    `
  })}
`;

/**
 * Export `Row` styled component.
 */

export const Row = styled.div<{
  disableHover?: boolean;
  isSelected?: boolean;
}>`
  display: contents;

  ${ifProp(
    'isSelected',
    css`
      ${Cell} {
        background-color: #f0f0f0;
      }
    `,
    css`
      ${ifNotProp(
        'disableHover',
        css`
          &:hover {
            ${Cell} {
              background-color: #f0f0f0;
            }
          }
        `
      )}
    `
  )}
`;

/**
 * Export `Icon` styled component.
 */

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

/**
 * Export `Content` styled component.
 */

export const Content = styled.div`
  padding: 32px;
`;
