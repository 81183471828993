/**
 * Module dependencies.
 */

import { Trade } from 'app/hooks/requests/apps-crypto/use-get-trades';
import React, { useMemo } from 'react';
import { StatusWrapper } from './trade-item.style';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { formatNumber } from '../../transactions/form-config';

/**
 * `Props` type.
 */

type Props = {
  item: Trade;
};

/**
 * `Badge` styled component.
 */

const Badge = styled.div`
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

/**
 * `Title` styled component.
 */

const Title = styled.div`
  color: #333;
  font-weight: 700;
`;

/**
 * `Value` styled component.
 */

const Value = styled.div``;

/**
 * Export `Status` component.
 */

export function Status({ item }: Props): JSX.Element {
  const { investment, profit, profitPerc, actualTotal } = useMemo(() => {
    let investment = new BigNumber('0');
    let actualTotal = new BigNumber('0');

    for (const obj of item.transactions) {
      investment = investment.plus(obj.total);
      actualTotal = actualTotal.plus(obj.actualTotal);
    }

    const profitPerc = actualTotal.dividedBy(investment).minus(1).multipliedBy(100).toFixed(2);

    return {
      investment: investment.toString(),
      actualTotal: actualTotal.toString(),
      profitPerc,
      profit: actualTotal.minus(investment).toString()
    };
  }, [item.transactions]);

  return (
    <StatusWrapper>
      <Badge>
        <Title>{'Investment'}</Title>

        <Value>{formatNumber(investment, '$')}</Value>
      </Badge>

      <Badge>
        <Title>{'Actual'}</Title>

        <Value>{formatNumber(actualTotal, '$')}</Value>
      </Badge>

      <Badge>
        <Title>{'Profit'}</Title>

        <Value>{formatNumber(profit, '$')}</Value>
      </Badge>

      <Badge>
        <Title>{'Profit %'}</Title>

        <Value>{profitPerc + '%'}</Value>
      </Badge>
    </StatusWrapper>
  );
}
