/**
 * Module dependencies.
 */

import { DatePickerField } from 'app/components/atoms/forms/fields/date-picker/date-picker-field';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputFormItem } from '../form-types';

/**
 * `Props` type.
 */

type Props = {
  name: string;
  item: InputFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormDatePickerField` component.
 */

export function FormDatePickerField(props: Props): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const { name, placeholder, label, rules, disabled, style } = item;

  return (
    <DatePickerField
      control={control}
      disabled={disabled}
      label={label}
      name={name}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      rules={rules}
      style={style}
    />
  );
}
