/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useGetTradesInvalidator } from './use-get-trades';
import { useGetTransactionsInvalidator } from './use-get-transactions';

/**
 * `Payload` type.
 */

type Payload = {
  tradeId: number;
  transactionId: number;
  type: 'add' | 'remove';
};

/**
 * Add transaction to trade.
 */

async function addTransactionToTrade(
  client: AxiosInstance,
  tradeId: number,
  transactionId: number,
  type: 'add' | 'remove'
): Promise<void> {
  await client({
    method: 'post',
    url: resolveAppUrl('appsCryptoTradeAddTransaction', { interpolations: { tradeId, transactionId, type } })
  });
}

/**
 * Export `useAddTransactionToTrade`.
 */

export function useAddTransactionToTrade() {
  const client = useRequestProvider();
  const invalidator = useGetTradesInvalidator();
  const transactionsInvalidator = useGetTransactionsInvalidator();

  return useMutation({
    mutationFn: async ({ tradeId, transactionId, type }: Payload) => {
      const result = await addTransactionToTrade(client, tradeId, transactionId, type);

      await invalidator();
      await transactionsInvalidator();

      return result;
    },
    onSuccess: () => {
      // Nothing...
    }
  });
}
