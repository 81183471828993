/**
 * Module dependencies.
 */

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { IoIosArrowForward } from 'react-icons/io';
import { Collapse } from 'antd';
import { Squash } from 'hamburger-react';

/**
 * Export `Page` styled component.
 */

export const Page = styled.div`
  background-color: red;
`;

/**
 * Export `LogoWrapper` styled component.
 */

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

/**
 * Export `Hamburger` styled component.
 */

export const Hamburger = styled(Squash).attrs({
  color: '#ffffff',
  size: 20
})`
  width: 24px;
`;

/**
 * `Logo` styled component.
 */

export const Logo = styled.div`
  color: white;
  display: flex;
  padding-left: 16px;
  align-items: center;
  justify-content: center;
  height: 64px;
`;

/**
 * Export `Header` styled component.
 */

export const Header = styled.div`
  background-color: #005866;
  min-height: 64px;
  flex-direction: row;
  display: grid;
  grid-template-columns: 280px 1fr;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

/**
 * Export `HeaderMenu` styled component.
 */

export const HeaderMenu = styled(Link)<{
  selected: boolean;
}>`
  background-color: #005866;
  flex-direction: row;
  display: flex;
  padding: 0 16px;

  ${ifProp(
    'selected',
    css`
      background-color: #00424d;
    `
  )}

  a {
    color: white;
  }
`;

/**
 * Export `HeaderMenuIcon` styled component.
 */

export const HeaderMenuIcon = styled.div`
  color: #3dc556;
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Export `HeaderMenuLabel` styled component.
 */

export const HeaderMenuLabel = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  color: white;
`;

/**
 * Export `Content` styled component.
 */

export const Content = styled.div`
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

/**
 * Export `SideMenu` styled component.
 */

export const SideMenu = styled.div<{
  isVisible: boolean;
}>`
  background-color: #00414b;
  width: 280px;
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  padding-top: 16px;
  margin-left: -300px;
  transition: 350ms ease-in-out;
  transition-property: all;
  z-index: 9999;

  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-left: -110vw;
  }

  ${ifProp(
    'isVisible',
    css`
      margin-left: 0;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    `
  )}
`;

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div<{
  isVisible: boolean;
}>`
  flex: 1;
  padding: 16px;
  margin-top: 64px;
  margin-left: 0;
  overflow-y: scroll;
  transition: 350ms ease-in-out;
  transition-property: all;

  ${ifProp(
    'isVisible',
    css`
      margin-left: 280px;
    `
  )}
`;

/**
 * Export `SideMenuItem` styled component.
 */

export const SideMenuItem = styled(Link)<{
  selected: boolean;
  extraPadding: number;
}>`
  background-color: #00414b;
  height: 64px;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 0 16px 0 ${props => props.extraPadding + 16}px;
  position: relative;
  color: white;

  a {
    color: white;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    opacity: 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: rgb(45, 93, 103);
    transition: 150ms ease-in-out;
    transition-property: all;
  }

  &:hover {
    &:before {
      opacity: 1;
      width: 102%;
    }
  }

  ${ifProp(
    'selected',
    css`
      &:before {
        opacity: 1;
        width: 102%;
      }
    `
  )}
`;

/**
 * Export `SideMenuItemIcon` styled component.
 */

export const SideMenuItemIcon = styled.div`
  padding: 8px;
  color: #1d9333;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: scale(1.5);
`;

/**
 * Export `SideMenuItemLabel` styled component.
 */

export const SideMenuItemLabel = styled.div`
  color: white;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 16px;
`;

/**
 * Export `ArrowWrapper` styled component.
 */

export const ArrowWrapper = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  position: relative;
`;

/**
 * Export `Arrow` styled component.
 */

export const Arrow = styled(IoIosArrowForward)<{
  isOpen: boolean;
}>`
  transform: rotate(${ifProp('isOpen', '90deg', '0deg')});
`;

/**
 * Export `StyledCollapse` styled component.
 */

export const StyledCollapse = styled(Collapse)`
  background-color: #00414b;
`;
