/**
 * Module dependencies.
 */

import React, { ReactNode, useEffect, useState } from 'react';
import { DropdownMenu, Label, Wrapper, WrapperMenu } from './menu.styles';
import { useContabBreakpoints } from 'app/hooks/use-contab-breakpoints';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `MobileMenu` component.
 */

export function MobileMenu({ children }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    function hideDiv() {
      console.log('OK!!!', visible);

      setVisible(false);
    }

    setTimeout(() => {
      window.addEventListener('click', hideDiv);
    }, 20);

    return () => {
      window.removeEventListener('click', hideDiv);
    };
  }, [visible]);

  return (
    <>
      <WrapperMenu
        onClick={() => {
          console.log('1');
          setVisible(!visible);
        }}
      >
        <Label>{'MENU'}</Label>
      </WrapperMenu>

      {visible && <DropdownMenu>{children}</DropdownMenu>}
    </>
  );
}

/**
 * Export `Menu` component.
 */

export function Menu({ children }: Props): JSX.Element {
  const { size } = useContabBreakpoints();

  if (size > 767) {
    return <Wrapper>{children}</Wrapper>;
  }

  return <MobileMenu>{children}</MobileMenu>;
}
