/**
 * Module dependencies.
 */

import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'app/components/atoms/button/button';
import { Header, StyledTitle } from './trades-list.styles';
import { useAddTrade } from 'app/hooks/requests/apps-crypto/use-add-trade';
import { useGetTrades } from 'app/hooks/requests/apps-crypto/use-get-trades';
import { TradeItem } from '../item/trade-item';

/**
 * Export `TradesList` component.
 */

export function TradesList(): JSX.Element {
  const [translate] = useTranslation();
  const { data } = useGetTrades();
  const { mutateAsync: addTrade } = useAddTrade();

  console.log('JSON data: ', JSON.stringify(data, null, 2));

  return (
    <div>
      <Header>
        <StyledTitle>{translate('crypto.trades.title')}</StyledTitle>

        <Button onClick={() => addTrade()} type={'primary'}>
          {'Add'}
        </Button>
      </Header>

      {(data ?? []).map(item => (
        <TradeItem item={item} key={item.id} />
      ))}
    </div>
  );
}
