/**
 * Module dependencies.
 */

import { Trade } from 'app/hooks/requests/apps-crypto/use-get-trades';
import React, { useCallback, useState } from 'react';
import { TransactionsWrapper } from './trade-item.style';
import { Button } from 'app/components/atoms/button/button';
import { AddTransactions } from './add-transactions';
import { Cell, GridTable, HeaderCell, HeaderRow, Row } from '../../overview/overview.styles';
import { useAddTransactionToTrade } from 'app/hooks/requests/apps-crypto/use-add-transaction-to-trade';
import { Status } from './status';
import BigNumber from 'bignumber.js';
import { formatNumber } from '../../transactions/form-config';
import moment from 'moment';
import { MinusCircleOutlined } from '@ant-design/icons';

/**
 * `Props` type.
 */

type Props = {
  item: Trade;
};

/**
 * Export `TradeTransactions` component.
 */

export function TradeTransactions({ item }: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: addTransaction } = useAddTransactionToTrade();
  const handleRemove = useCallback(
    (obj: any) => {
      return addTransaction({
        tradeId: item.id,
        transactionId: obj.id,
        type: 'remove'
      });
    },
    [addTransaction, item.id]
  );

  console.log(item);

  return (
    <>
      <TransactionsWrapper>
        <Button onClick={() => setShowModal(true)} type={'primary'}>
          {'Add Transaction'}
        </Button>
      </TransactionsWrapper>

      <GridTable columns={13}>
        <HeaderRow>
          <HeaderCell>{'ID'}</HeaderCell>
          <HeaderCell>{'Coin'}</HeaderCell>
          <HeaderCell>{'Amount'}</HeaderCell>
          <HeaderCell>{'Unit Price'}</HeaderCell>
          <HeaderCell>{'Total'}</HeaderCell>
          <HeaderCell>{'Actual Price'}</HeaderCell>
          <HeaderCell>{'Actual Total'}</HeaderCell>
          <HeaderCell width={50}>{'Type'}</HeaderCell>
          <HeaderCell>{'Profit %'}</HeaderCell>
          <HeaderCell>{'Profit $'}</HeaderCell>
          <HeaderCell>{'Created At'}</HeaderCell>
          <HeaderCell>{'Updated At'}</HeaderCell>
          <HeaderCell>{'Actions'}</HeaderCell>
        </HeaderRow>

        {(item.transactions ?? []).map((transaction, index) => {
          const isNegative = transaction.type === 'trade' && new BigNumber(transaction.profitPercentage).isLessThan(0);
          const isPositive =
            transaction.type === 'trade' && new BigNumber(transaction.profitPercentage).isGreaterThan(0);

          console.log(transaction);

          return (
            <Row disableHover key={index}>
              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                type={transaction.currentType}
              >
                {transaction.id}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                type={transaction.currentType}
              >
                {transaction.coin}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {transaction.amount}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {formatNumber(transaction.unitPrice, '$')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {formatNumber(transaction.total, '$')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {formatNumber(transaction.actualPrice, '$')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {formatNumber(transaction.actualTotal, '$')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {transaction.type}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {new BigNumber(transaction.profitPercentage).toFixed(2).toString() + '%'}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {formatNumber(transaction.profitDollar, '$')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                {moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </Cell>

              <Cell
                closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
                isNegative={isNegative}
                isPositive={isPositive}
                right
                type={transaction.currentType}
              >
                <Button icon={<MinusCircleOutlined />} onClick={() => handleRemove(transaction)} size={'small'} />
              </Cell>
            </Row>
          );
        })}
      </GridTable>

      <Status item={item} />

      <AddTransactions isOpen={showModal} item={item} onClose={() => setShowModal(false)} />
    </>
  );
}
