/**
 * Module dependencies.
 */

import React from 'react';
import { ColumnsWidget } from '../form-types';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Col, Row } from 'antd';
import { CrudFormRender } from '../crud-form';

/**
 * `Props` type.
 */

type Props = {
  formType: 'add' | 'edit';
  name: string;
  item: ColumnsWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `Columns` component.
 */

export function Columns({ form, item, formType }: Props): JSX.Element {
  return (
    <Row gutter={item.gutter}>
      {item.columns.map((column, index) => {
        return (
          <Col key={index} span={column.column} style={column.style}>
            <CrudFormRender form={form} formFields={column.children} formType={formType} />
          </Col>
        );
      })}
    </Row>
  );
}
