/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useGetTradesInvalidator } from './use-get-trades';

/**
 * Add trade.
 */

async function addTrade(client: AxiosInstance): Promise<void> {
  await client({
    method: 'post',
    url: resolveAppUrl('appsCryptoTradeAdd')
  });
}

/**
 * Export `useAddTrade`.
 */

export function useAddTrade() {
  const client = useRequestProvider();
  const invalidator = useGetTradesInvalidator();

  return useMutation({
    mutationFn: async () => {
      const result = await addTrade(client);

      await invalidator();

      return result;
    },
    onSuccess: () => {
      // Nothing...
    }
  });
}
