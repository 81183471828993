/**
 * Module dependencies.
 */

import { compileMenu } from 'app/core/utils/menu';
import { appRoutes } from 'app/routes';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiTwotoneBank } from 'react-icons/ai';
import { SiWebmoney } from 'react-icons/si';
import { SettingOutlined, AppstoreAddOutlined, LineChartOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Hamburger,
  Header,
  HeaderMenu,
  HeaderMenuIcon,
  HeaderMenuLabel,
  Logo,
  LogoWrapper,
  Page
} from './dashboard.styles';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import { MenuItem } from 'app/types/navigation';
import { sortBy } from 'lodash';
import { useCollapseMenuProvider } from 'app/components/providers/collapse-menu-provider/collapse-menu-provider';
import { useContabBreakpoints } from 'app/hooks/use-contab-breakpoints';
import { Menu } from 'app/components/molecules/menu/menu';

/**
 * Flat items.
 */

function flatItems(items: MenuItem[], parent?: MenuItem): MenuItem[] {
  let result: MenuItem[] = [];

  for (const item of items) {
    if (item.children) {
      result = [...result, ...flatItems(item.children, item)];
    }

    result.push({
      ...item,
      parent
    });
  }

  return result;
}

/**
 * Resolve selected keys.
 */

export function resolveSelectedKeys(pathname: string, items: MenuItem[]): MenuItem {
  let item = flatItems(items).find(item => {
    return pathname === item.pathname;
  });

  if (item) {
    return item;
  }

  item = sortBy(flatItems(items), item => item?.pathname?.length ?? 0 * -1)
    .reverse()
    .find(item => {
      return pathname.startsWith(item?.pathname ?? '');
    });

  if (item) {
    return item;
  }

  return items?.[0];
}

/**
 * Menu config.
 */

export const menuConfig = [
  {
    key: '1',
    pathname: appRoutes.dashboard.home.base,
    icon: <LineChartOutlined />,
    label: 'menu.dashboard'
  },
  {
    key: '2',
    pathname: appRoutes.dashboard.bankAccounts.base,
    icon: <AiTwotoneBank />,
    label: 'menu.bankAccounts'
  },
  {
    key: '3',
    pathname: appRoutes.dashboard.projects.base,
    icon: <AppstoreAddOutlined />,
    label: 'menu.projects'
  },
  {
    key: '4',
    pathname: appRoutes.dashboard.appsCrypto.base,
    icon: <SiWebmoney />,
    label: 'menu.appsCrypto'
  },
  {
    key: '5',
    pathname: appRoutes.dashboard.settings.base,
    icon: <SettingOutlined />,
    label: 'menu.settings'
  }
];

/**
 * Export `DashboardHeader` component.
 */

export function DashboardHeader(): JSX.Element | null {
  const [translate] = useTranslation();
  const { size } = useContabBreakpoints();
  const [fixed, setFixed] = useState(false);
  const { collapsed, setCollapsed } = useCollapseMenuProvider();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useMemo(() => cookieStorage.get('user'), []);
  const items = useMemo(
    () =>
      compileMenu(
        menuConfig.map(item => ({
          ...item,
          label: translate(item.label)
        }))
      ),
    [translate]
  );

  const selectedItem = useMemo(() => resolveSelectedKeys(pathname, items), [items, pathname]);

  useEffect(() => {
    if (pathname === appRoutes.dashboard.base) {
      navigate(appRoutes.dashboard.home.base);
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (!user) {
      navigate(appRoutes.home);
    }
  }, [navigate, user]);

  useEffect(() => {
    if (size < 767) {
      return;
    }

    function onMouseMove(event: MouseEvent) {
      const { clientX } = event;

      if (fixed) {
        return;
      }

      console.log('##', clientX);

      if (clientX < 30 && !collapsed) {
        setCollapsed(true);
      }

      if (clientX > 270 && !!collapsed) {
        setCollapsed(false);
      }
    }

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [collapsed, fixed, setCollapsed, size]);

  if (!user) {
    return null;
  }

  return (
    <Page>
      <Header>
        <LogoWrapper>
          <Hamburger
            toggle={value => {
              if (!value && fixed) {
                setFixed(false);
              }

              if (collapsed && value) {
                setFixed(true);

                return;
              }

              setFixed(value);

              setCollapsed(value as boolean);
            }}
            toggled={fixed}
          />

          <Logo>{'CONTAB'}</Logo>
        </LogoWrapper>

        <Menu>
          {items.map(item => (
            <HeaderMenu key={item.key} selected={selectedItem?.key === item.key} to={item.pathname as any}>
              <HeaderMenuIcon>{item.icon}</HeaderMenuIcon>
              <HeaderMenuLabel>{item.label}</HeaderMenuLabel>
            </HeaderMenu>
          ))}
        </Menu>
      </Header>

      <Outlet />
    </Page>
  );
}
