/**
 * Export `appRoutes`.
 */

export const appRoutes = {
  home: '/',
  dashboard: {
    appsCrypto: {
      base: '/dashboard/apps-crypto',
      overview: '/dashboard/apps-crypto',
      transactions: '/dashboard/apps-crypto/transactions',
      providers: '/dashboard/apps-crypto/providers',
      trades: '/dashboard/apps-crypto/trades',
      tradesAdd: '/dashboard/apps-crypto/trades/add'
    },
    base: '/dashboard',
    home: {
      base: '/dashboard/home'
    },
    projects: {
      base: '/dashboard/projects',
      addNewProject: '/dashboard/projects/add',
      project: {
        overview: '/dashboard/projects/project/:projectId',
        suppliers: '/dashboard/projects/project/:projectId/suppliers',
        proposals: '/dashboard/projects/project/:projectId/proposals',
        suppliersContacts: '/dashboard/projects/project/:projectId/suppliers/:supplierId/contacts',
        budgets: '/dashboard/projects/project/:projectId/budgets',
        movements: '/dashboard/projects/project/:projectId/movements',
        paymentTypes: '/dashboard/projects/project/:projectId/payment-types',
        settings: '/dashboard/projects/project/:projectId/settings'
      }
    },
    bankAccounts: {
      movementsManager: {
        base: '/dashboard/bank-accounts/manager',
        add: '/dashboard/bank-accounts/manager/add',
        edit: '/dashboard/bank-accounts/manager/edit/:id'
      },
      accounts: {
        connect: '/dashboard/bank-accounts/accounts/connect/:id',
        sync: '/dashboard/bank-accounts/accounts/connect/:accountId/:integrationId/sync',
        base: '/dashboard/bank-accounts/accounts',
        add: '/dashboard/bank-accounts/accounts/add'
      },
      categories: {
        base: '/dashboard/bank-accounts/categories',
        add: '/dashboard/bank-accounts/categories/add'
      },
      subCategories: {
        base: '/dashboard/bank-accounts/subCategories',
        add: '/dashboard/bank-accounts/subCategories/add'
      },
      budgets: {
        base: '/dashboard/bank-accounts/budgets',
        add: '/dashboard/bank-accounts/budgets/add',
        edit: '/dashboard/bank-accounts/budgets/edit/:id'
      },
      base: '/dashboard/bank-accounts'
    },
    settings: {
      base: '/dashboard/settings',
      general: '/dashboard/settings',
      profile: '/dashboard/settings/profile'
    }
  }
};
