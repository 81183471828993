/**
 * Module dependencies.
 */

import { InputNumberField } from 'app/components/atoms/forms/fields/input-number/input-number-field';
import React, { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputCurrencyFormItem } from '../form-types';
import { FormSelectField } from './form-select-field';

/**
 * `FormInputNumberProps` type.
 */

export type FormInputNumberProps = {
  name: string;
  item: InputCurrencyFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormInputCurrency` component.
 */

export function FormInputCurrency(props: FormInputNumberProps): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const { name, placeholder, addonAfter, label, disabled, rules, onChange, formatter, parser } = item;

  const handleChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange(value, form);
      }
    },
    [form, onChange]
  );

  return (
    <InputNumberField
      addonAfter={
        addonAfter?.(form) ??
        (item.currencies && (
          <FormSelectField
            form={form}
            item={{
              type: 'selectField',
              minimal: true,
              name: item.currencies.name,
              label: '',
              options: item.currencies.options,
              rules: item.currencies.rules
            }}
            name={item.currencies.name}
          />
        ))
      }
      control={control}
      disabled={disabled}
      formatter={formatter}
      htmlType={'number'}
      label={label}
      name={name}
      onChange={handleChange}
      parser={parser}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      rules={rules}
    />
  );
}
