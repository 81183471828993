/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    size: '1fr',
    key: 'name'
  },
  {
    title: translate('common.table.columns.apiKey'),
    dataIndex: 'apiKey',
    size: '1fr',
    key: 'apiKey'
  },
  {
    title: translate('common.table.columns.secret'),
    dataIndex: 'apiSecret',
    size: '1fr',
    key: 'apiSecret'
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'apiKey',
    label: translate('common.labels.apiKey'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'apiSecret',
    label: translate('common.labels.secret'),
    rules: requiredRule(translate)
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate)
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate)
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.appsCrypto.providers,
  key: ['categories'],
  endpoint: apiEndpoints.appsCryptoProviders,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createAppsCryptoProvidersTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
