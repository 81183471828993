/**
 * Module dependencies.
 */

import { Transaction } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';
import React from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  item: Transaction;
};

/**
 * `Amount` styled component.
 */

const Amount = styled.div``;

/**
 * `ID` styled component.
 */

const ID = styled.div`
  font-weight: 600;
  margin-right: 8px;
  font-size: 12px;
  color: #ccc;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * Export `OptionTransactionLabel` component.
 */

export function OptionTransactionLabel({ item }: Props): JSX.Element {
  return (
    <Wrapper>
      <ID>{item.id}</ID>

      <Amount>{`${item.amount} ${item.coin}`}</Amount>
    </Wrapper>
  );
}
