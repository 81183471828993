/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import React from 'react';
import { Actions, FormContent, Wrapper } from './header-search.styles';
import { Select } from 'app/components/atoms/forms/fields/select/select';
import { Provider } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';

/**
 * `Filters` type.
 */

export type Filters = {
  coins: string[];
  providers: number[];
  search: string | null;
};

/**
 * `Props` type.
 */

type Props = {
  coinsList: string[];
  providersList: Provider[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
  refetch: () => void;
};

/**
 * Export `HeaderSearch` component.
 */

export function HeaderSearch({ coinsList, providersList, refetch, setFilters, filters }: Props): JSX.Element {
  return (
    <Wrapper>
      <FormContent>
        <Select
          allowClear={true}
          filterOption={(input, option) => ((option?.label as any) ?? '').toLowerCase().includes(input.toLowerCase())}
          label={'Coin'}
          mode={'multiple'}
          name={'coin'}
          onChange={value => {
            setFilters({
              ...filters,
              coins: value
            });
          }}
          options={(coinsList ?? []).map(item => ({
            value: item,
            label: item
          }))}
          showSearch={true}
          value={filters.coins || []}
        />

        <Select
          allowClear={true}
          filterOption={(input, option) => ((option?.label as any) ?? '').toLowerCase().includes(input.toLowerCase())}
          label={'Provider'}
          mode={'multiple'}
          name={'provider'}
          onChange={value => {
            setFilters({
              ...filters,
              providers: value
            });
          }}
          options={(providersList ?? []).map(item => ({
            value: item.id,
            label: item.name
          }))}
          showSearch={true}
          value={filters.providers || []}
        />
      </FormContent>

      <Actions>
        <Button onClick={refetch}>{'Refresh'}</Button>
      </Actions>
    </Wrapper>
  );
}
