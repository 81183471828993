/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './date-picker.module.less';
import moment from 'moment';

/**
 * `DatePickerProps` type.
 */

export type DatePickerProps = Omit<AntdDatePickerProps, 'error'> & {
  error: any;
  className?: string;
  invalid?: boolean;
  isDirty?: boolean;
  label: string;
  isTouched?: boolean;
};

/**
 * Export `DatePickerComponent` component.
 */

export function DatePickerComponent(props: DatePickerProps, forwardedRef: any): JSX.Element | null {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, isTouched, isDirty, label, onChange, value, ...rest } = props;
  const { error } = rest;

  if (!!onChange && !!value && typeof value === 'string') {
    (onChange as any)(moment(value));

    return null;
  }

  return (
    <FormField className={className} label={label}>
      <AntdDatePicker className={styles.wrapper} ref={forwardedRef} {...rest} onChange={onChange} value={value} />

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `DatePicker` component.
 */

export const DatePicker = forwardRef<any, DatePickerProps>(DatePickerComponent);
