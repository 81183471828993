/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Transaction } from './use-get-apps-crypto-overview';

/**
 * Export `Trade`.
 */

export type Trade = {
  id: number;
  objectivePercentage: string;
  dateOfCreation: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  transactions: Transaction[];
};

/**
 * Get trades.
 */

function getTrades(client: AxiosInstance): Promise<Trade[]> {
  return client.get(resolveAppUrl('appsCryptoTrades')).then(({ data }) => data?.data);
}

/**
 * Export `useGetTradesInvalidator`.
 */

export function useGetTradesInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['getTrades']);
  }, [queryClient]);
}

/**
 * Export `useGetTrades`.
 */

export function useGetTrades() {
  const client = useRequestProvider();

  return useQuery(['getTrades'], {
    queryFn: async () => {
      return await getTrades(client);
    }
  });
}
