/**
 * Module dependencies.
 */

import { Popconfirm } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteTransaction } from 'app/hooks/requests/apps-crypto/use-delete-transaction';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';

/**
 * `Props` type.
 */

type Props = {
  item: Transaction;
  setPause: (value: boolean) => void;
  refetch: () => any;
};

/**
 * Export `RemoveAction` component.
 */

export function RemoveAction({ item, setPause, refetch }: Props): JSX.Element {
  const [translate] = useTranslation();
  const { mutate: deleteAction, isLoading } = useDeleteTransaction();
  return (
    <>
      <Popconfirm
        okButtonProps={{ loading: isLoading }}
        {...{ onClick: (event: any) => event.stopPropagation() }}
        onConfirm={(event: any) => {
          event.stopPropagation();
          deleteAction(item.id);
        }}
        onOpenChange={open => {
          setPause(open);

          if (!open) {
            refetch();
          }
        }}
        title={translate('removeAction')}
      >
        <Button danger icon={<DeleteOutlined />} size={'small'} />
      </Popconfirm>
    </>
  );
}
