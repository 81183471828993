/**
 * Module dependencies.
 */

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useBankAccountFirstRefreshTransactions } from 'app/hooks/requests/bank-accounts/use-bank-account-fisrt-refresh-transactions';
import { useCallback } from 'react';
import { appRoutes } from 'app/routes';
import { Loading } from 'app/components/atoms/loading/loading';
import React from 'react';
import { useBankAccountLinkStatus } from 'app/hooks/requests/bank-accounts/use-bank-account-status';
import styles from './bank-account-connect.module.less';
import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import { DatePickerField } from 'app/components/atoms/forms/fields/date-picker/date-picker-field';
import moment from 'moment';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Field } from 'app/components/organisms/field/field';
import { DefaultInfo } from './info/default-info';

/**
 * Export `BankAccountSync` component.
 */

export function BankAccountSync(): JSX.Element {
  const params = useParams();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: refreshTransactions, isLoading: isLoadingRefreshTransactions } =
    useBankAccountFirstRefreshTransactions();

  const { data, isLoading } = useBankAccountLinkStatus(params.integrationId);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'all'
  });

  const onSubmit = useCallback(
    async ({ date }: any) => {
      try {
        await refreshTransactions({
          accountId: parseFloat(params.accountId ?? '-1'),
          integrationId: params.integrationId ?? '',
          transactionsDate: date ? date.toISOString() : undefined
        });

        navigate(appRoutes.dashboard.bankAccounts.accounts.base);
      } catch (error: any) {
        const data = error.response?.data?.data?.data;
        console.log(data);
        console.log(error);
      }
    },
    [navigate, params.accountId, params.integrationId, refreshTransactions]
  );

  return (
    <>
      <Loading isLoading={isLoading}>
        {!!data && (
          <div className={styles.integrationInformation}>
            <img src={data.image} />

            <Field title={translate('common.labels.id')} value={data.id} />

            <DefaultInfo data={data} />
          </div>
        )}

        {!data?.reconnectState && (
          <SubTitle className={styles.subTitle}>{translate('bankAccounts.sync.startSyncing')}</SubTitle>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {!data?.reconnectState && (
            <DatePickerField
              control={control}
              disabledDate={(date: any) => {
                const days = date.diff(moment(), 'days');
                const max = parseInt('90', 10) * -1;

                return days >= 0 || days <= max;
              }}
              label={translate('common.labels.date')}
              name={'date'}
              placeholder={translate('common.labels.date')}
              rules={{}}
            />
          )}

          <SubmitButton
            disabled={isSubmitting}
            label={translate(data?.reconnectState ? 'common.actions.finish' : 'common.actions.startSync')}
            loading={isLoadingRefreshTransactions}
          />
        </form>
      </Loading>
    </>
  );
}
