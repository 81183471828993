/**
 * Module dependencies.
 */

import { Trade } from 'app/hooks/requests/apps-crypto/use-get-trades';
import React from 'react';
import { Wrapper } from './trade-item.style';
import { TradeHeader } from './trade-header';
import { TradeTransactions } from './trade-transactions';

/**
 * `Props` type.
 */

type Props = {
  item: Trade;
};

/**
 * Export `TradeItem` component.
 */

export function TradeItem({ item }: Props): JSX.Element {
  return (
    <Wrapper>
      <TradeHeader item={item} />

      <TradeTransactions item={item} />
    </Wrapper>
  );
}
