/**
 * Module dependencies.
 */

import { Typography } from 'antd';
import styled from 'styled-components';

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * Export `WrapperMenu` styled component.
 */

export const WrapperMenu = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  justify-content: center;
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled(Typography.Text)`
  color: white;
`;

/**
 * Export `DropdownMenu` styled component.
 */

export const DropdownMenu = styled.div`
  background-color: red;
  position: fixed;
  z-index: 10000;
  top: 60px;
  left: 10px;
  right: 10px;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
`;
