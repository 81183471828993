/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 16px;
  border-radius: 6px;
`;

/**
 * Export `HeaderForm` styled component.
 */

export const HeaderForm = styled.div`
  background-color: #efefef;
  padding: 16px;
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

/**
 * Export `TransactionsWrapper` styled component.
 */

export const TransactionsWrapper = styled.div`
  padding: 16px;
`;

/**
 * Export `StatusWrapper` styled component.
 */

export const StatusWrapper = styled.div`
  background-color: #ddd;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  padding: 16px;
  border-radius: 8px;
  flex-direction: row;
  display: flex;
`;
