/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  background-color: #fafafa;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

/**
 * Export `Actions` styled component.
 */

export const Actions = styled.div`
  display: block;
`;

/**
 * Export `FormContent` styled component.
 */

export const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
