/**
 * Module dependencies.
 */

import React from 'react';
import { reduce } from 'lodash';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { Field } from 'app/components/organisms/field/field';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid #ccc;
  margin-top: 32px;
  margin-bottom: 8px;
  border-radius: 8px;
`;

/**
 * `Error` styled component.
 */

const Error = styled.div`
  margin-top: 8px;
  color: red;
`;

/**
 * Export `LinkedStatusTransaction` component.
 */

export function LinkedStatusTransaction(props: any): JSX.Element {
  const linkedTransactions = props.form.cache.hooks?.linked?.data ?? [];
  const [amount, linked] = props.form.getValues(['amount', 'linked']);
  const linkedItems = linkedTransactions.filter((item: any) => {
    return (linked ?? []).includes(item.id);
  });

  const total = reduce(
    linkedItems,
    (acc: any, item: any) => {
      return new BigNumber(acc).plus(item.amount).toString();
    },
    '0'
  );

  const result = new BigNumber(total).plus(amount);

  return (
    <Wrapper>
      <Field title={'Amount'} value={amount} />

      <Field title={'LinkedTransactions'} value={total} />

      <Field danger={result.isGreaterThan(0)} last title={'Total'} value={result.toString()} />

      {result.isGreaterThan(0) && <Error>{'The value overflow the sell transaction'}</Error>}
    </Wrapper>
  );
}
