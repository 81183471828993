/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Filters } from 'app/components/pages/apps-crypto/overview/components/header-search';

/**
 * `Provider` type.
 */

export type Provider = {
  id: number;
  name: string;
  apiKey: string;
  apiSecret: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
};

/**
 * `Transaction` type.
 */

export type Transaction = {
  id: number;
  coin: string;
  amount: string;
  unitPrice: string;
  providerId: string;
  provider: Provider;
  closedUnitPrice: string | undefined;
  closedTotal: string | undefined;
  total: string;
  closedBy: Transaction[];
  closedTo: Transaction[];
  actualPrice: string;
  actualTotal: string;
  currentTotal: string;
  profitPercentage: string;
  profitDollar: string;
  currentProfitPercentage: string;
  currentProfit: string;
  type: string;
  currentType: 'running' | 'airdrop' | 'closedWin' | 'closedLoss' | 'idle' | 'finished';
  notes: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
};

/**
 * `TransactionStatus` type.
 */

export type TransactionStatus = {
  realizedProfit: string;
  total: string;
  tradeInvest: string;
  tradeCurrent: string;
  tradeProfitPercentage: string;
  tradeProfitDollar: string;
};

/**
 * `CryptoAsset` type.
 */

export type CryptoAsset = {
  coin: string;
  icon: string;
  providerId: string;
  provider: Provider;
  amount: string;
  totalInvest: string;
  unitPrice: string;
  actualPrice: string;
  actualTotal: string;
  type: 'running' | 'airdrop' | 'closedWin' | 'closedLoss';
  profitPercentage: string;
  profitDollar: string;
  positiveTransactions: number;
  transactions: Array<Transaction>;
  transactionsStatus: TransactionStatus;
};

/**
 * `CryptoOverview` type.
 */

type CryptoOverview = {
  invest: string;
  lists: {
    coins: string[];
    providers: Provider[];
  };
  total: string;
  profit: string;
  positive: string;
  negative: string;
  positiveCount: number;
  negativeCount: number;
  totalAirdrop: string;
  coins: CryptoAsset[];
};

/**
 * Get apps crypto overview.
 */

function getAppsCryptoOverview(client: AxiosInstance, filters: Filters): Promise<CryptoOverview> {
  return client
    .get(
      resolveAppUrl('appsCryptoOverview', {
        params: filters
      })
    )
    .then(({ data }) => data);
}

/**
 * Export `useCryptoOverviewInvalidator`.
 */

export function useCryptoOverviewInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['useGetAppsCryptoOverview']);
  }, [queryClient]);
}

/**
 * Export `useGetAppsCryptoOverview`.
 */

export function useGetAppsCryptoOverview(filters: Filters) {
  const client = useRequestProvider();

  return useQuery(['useGetAppsCryptoOverview'], {
    queryFn: async () => {
      return await getAppsCryptoOverview(client, filters);
    }
  });
}
