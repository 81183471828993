/**
 * Module dependencies.
 */

import { Trade } from 'app/hooks/requests/apps-crypto/use-get-trades';
import React, { useEffect, useRef } from 'react';
import { HeaderForm } from './trade-item.style';
import { useForm } from 'react-hook-form';
import { FormInputField } from 'app/components/organisms/crud/form-components/form-input-field';
import { FormDatePickerField } from 'app/components/organisms/crud/form-components/form-date-picker-field';
import { FormInputNumber } from 'app/components/organisms/crud/form-components/form-input-number';
import { pick } from 'lodash';
import { useEditTrade } from 'app/hooks/requests/apps-crypto/use-edit-trade';
import { Popconfirm } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteTrade } from 'app/hooks/requests/apps-crypto/use-delete-trade';

/**
 * `Props` type.
 */

type Props = {
  item: Trade;
};

/**
 * Export `TradeHeader` component.
 */

export function TradeHeader({ item }: Props): JSX.Element {
  const { mutateAsync: editTrade } = useEditTrade();
  const { mutateAsync: deleteTrade } = useDeleteTrade();
  const oldData = useRef<any>();
  const form = useForm({
    values: {
      ...pick(item, ['name', 'dateOfCreation', 'objectivePercentage'])
    },
    mode: 'all'
  });

  const values = form.watch();

  useEffect(() => {
    if (oldData.current === undefined) {
      oldData.current = JSON.stringify(values);

      return;
    }

    const key = JSON.stringify(values);

    if (oldData.current === key) {
      return;
    }

    oldData.current = key;

    const timer = setTimeout(() => {
      editTrade({
        id: item.id,
        payload: {
          ...values,
          objectivePercentage: values.objectivePercentage !== null ? String(values.objectivePercentage) : ''
        }
      });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [editTrade, item.id, values]);

  return (
    <HeaderForm>
      <FormInputField
        form={form as any}
        item={{
          name: 'name',
          label: 'Trade Name',
          type: 'inputField',
          style: {
            width: '480px'
          },
          rules: undefined
        }}
        name={'name'}
      />

      <FormDatePickerField
        form={form as any}
        item={{
          style: {
            width: '200px'
          },
          name: 'dateOfCreation',
          label: 'Date Of creation',
          type: 'inputField',
          rules: undefined
        }}
        name={'dateOfCreation'}
      />

      <FormInputNumber
        form={form as any}
        item={{
          name: 'objectivePercentage',
          label: 'Objective Percentage',
          formatter: item => item,
          parser: item => item,
          type: 'inputNumberField',
          rules: undefined
        }}
        name={'objectivePercentage'}
      />

      <Popconfirm
        okButtonProps={{ loading: false }}
        {...{ onClick: (event: any) => event.stopPropagation() }}
        onConfirm={(event: any) => {
          event.stopPropagation();
          deleteTrade(item.id);
        }}
        title={'Are you sure?'}
      >
        <Button danger icon={<DeleteOutlined />} size={'small'} />
      </Popconfirm>
    </HeaderForm>
  );
}
