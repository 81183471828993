/**
 * Module dependencies.
 */

import { MenuItem } from 'app/types/navigation';
import React, { useState } from 'react';
import { Arrow, ArrowWrapper, SideMenuItem, SideMenuItemIcon, SideMenuItemLabel } from './dashboard.styles';
import { Expanded } from 'app/components/organisms/table/expanded';

/**
 * `Props` type.
 */

type Props = {
  item: MenuItem;
  selectedItem: MenuItem | undefined;
  level?: number;
};

/**
 * Export `ItemMenu` component.
 */

export function ItemMenu({ item, selectedItem, level = 0 }: Props): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <SideMenuItem
        extraPadding={level * 16}
        key={item.pathname}
        onClick={() => {
          setExpanded(!expanded);
        }}
        selected={selectedItem?.key === item.key}
        to={item.pathname as any}
      >
        <SideMenuItemIcon>{item.icon}</SideMenuItemIcon>
        <SideMenuItemLabel>{item.label}</SideMenuItemLabel>

        {item.children && (
          <ArrowWrapper>
            <Arrow isOpen={expanded} />
          </ArrowWrapper>
        )}
      </SideMenuItem>

      <Expanded expanded={expanded}>
        <>
          {item.children?.map(child => (
            <ItemMenu item={child} key={child.key} level={level + 1} selectedItem={selectedItem} />
          ))}
        </>
      </Expanded>
    </>
  );
}
