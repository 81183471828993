/**
 * Export `apiEndpoints`.
 */

export const apiEndpoints = {
  appsCryptoProviders: '/apps/crypto/providers',
  appsCryptoOverview: '/apps/crypto/overview',
  appsCryptoTransactions: '/apps/crypto/transactions',
  appsCryptoDeleteTransaction: '/apps/crypto/transactions/${id}',
  appsCryptoTrades: '/apps/crypto/trades',
  appsCryptoTradeAdd: '/apps/crypto/trades',
  appsCryptoTradeEdit: '/apps/crypto/trades/${id}',
  appsCryptoTradeDelete: '/apps/crypto/trades/${id}',
  appsCryptoTradeAddTransaction: '/apps/crypto/trades/${tradeId}/manage-trade/${type}/${transactionId}',
  cryptoAccounts: '/crypto/accounts',
  cryptoWallets: '/crypto/wallets',
  cryptoOverview: '/crypto/overview',
  cryptoAssets: '/crypto/assets',
  dashboardOverview: '/dashboard/overview',
  categories: '/lists/categories',
  categoriesAdd: '/lists/categories',
  categoriesDelete: '/lists/categories/${id}',
  subCategoriesDelete: '/lists/sub-categories/${id}',
  subCategories: '/lists/sub-categories',
  subCategoriesAdd: '/lists/sub-categories',
  budgets: '/lists/budgets',
  budgetsById: 'lists/budgets/${id}',
  budgetsAdd: '/lists/budgets',
  budgetsDelete: 'lists/budgets/${id}',
  budgetsEdit: 'lists/budgets/${id}',
  bankAccount: '/lists/bank-accounts',
  bankAccountGet: '/lists/bank-accounts/${id}',
  bankList: '/bank-accounts/bank-list',
  bankAccountRefresh: '/lists/bank-accounts/${id}/refresh',
  bankAccountAdd: '/lists/bank-accounts',
  bankAccountDelete: '/lists/bank-accounts/${id}',
  bankAccountConnectGet: '/bank-accounts/connect/${country}',
  bankAccountConnectRebuild: '/bank-accounts/connect/${accountId}/rebuild',
  bankAccountCleanTransactions: '/bank-accounts/connect/${accountId}/clean-transactions',
  bankAccountConnectRedirectUrl: '/bank-accounts/connect/redirect-url',
  bankAccountConnectLocalConnection: '/bank-accounts/connect/local',
  bankAccountConnectDeleteConnection: '/bank-accounts/connect/delete/${bankAccount}',
  bankAccountConnectLinks: '/bank-accounts/connect/sync-accounts',
  bankAccountConnectStatus: '/bank-accounts/connect/sync-accounts/status/${integrationId}',
  bankAccountConnectFirstRefreshTransactions: '/bank-accounts/connect/${accountId}/sync/${referenceId}/transactions',
  coins: 'lists/coins',
  transactions: '/transactions',
  transactionsByRange: '/transactions/range',
  transactionsFiltersYear: '/transactions/filters/year',
  transactionsFiltersMonth: '/transactions/filters/months',
  transactionsAdd: '/transactions',
  transactionsEdit: '/transactions/${id}',
  transactionById: '/transactions/${id}',
  loginGoogle: '/auth/google',
  logout: '/auth/logout',
  movementsOverview: '/movements/overview',
  backup: '/backup/database',
  restore: '/restore/database',
  projects: '/projects',
  projectsAdd: '/projects',
  projectById: '/projects/${id}',
  projectSuppliers: '/projects/${projectId}/suppliers',
  projectProposals: '/projects/${projectId}/proposals',
  projectMovements: '/projects/${projectId}/movements',
  projectProposalsById: '/projects/${projectId}/proposals/${proposalId}',
  projectProposalsByIds: '/projects/${projectId}/proposals-ids',
  projectSuppliersContacts: '/projects/${projectId}/suppliers/${supplierId}/contacts',
  projectBudgets: '/projects/${projectId}/budgets',
  projectPaymentTypes: '/projects/${projectId}/payment-types'
} as const;
