/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import { useTranslation } from 'react-i18next';
import { useGetAppsCryptoOverview } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';
import React, { useEffect, useState } from 'react';
import styles from './overview.module.less';
import {
  BadgeContainer,
  Cell,
  Content,
  GridTable,
  HeaderCell,
  HeaderRow,
  HeaderTable,
  Icon,
  Row,
  SpaceBetween
} from './overview.styles';
import { formatNumber } from '../transactions/form-config';
import BigNumber from 'bignumber.js';
import { Expanded } from 'app/components/organisms/table/expanded';
import { Badge, Tag } from 'antd';
import { Filters, HeaderSearch } from './components/header-search';
import { useSearchParams } from 'react-router-dom';
import { compact } from 'lodash';
import { TransactionsList } from './components/transactions-list';

/**
 * `CellShow` type.
 */

type CellShow = {
  item: string;
  visible: boolean;
};

/**
 * Export `AppsCryptoOverview` component.
 */

export function AppsCryptoOverview(): JSX.Element {
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pause, setPause] = useState(false);
  const [expandedCell, expandCell] = useState<CellShow>({
    item: '',
    visible: false
  });

  const [filters, setFilters] = useState<Filters>({
    providers: compact(searchParams.get('providers')?.split(',')).map(item => parseFloat(item)),
    coins: compact(searchParams.get('coins')?.split(',')),
    search: null
  });

  useEffect(() => {
    if (filters) {
      setSearchParams(params => {
        params.set('coins', filters.coins.join(','));
        params.set('providers', filters.providers.join(','));

        return params;
      });
    }
  }, [filters, setSearchParams]);

  const { data, refetch } = useGetAppsCryptoOverview(filters);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    async function loop() {
      if (filters) {
        refetch();
      }

      if (timer) {
        clearTimeout(timer);
      }

      if (pause) {
        return;
      }

      timer = setTimeout(() => {
        loop();
      }, 5000);
    }

    loop();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [filters, pause, refetch]);

  return (
    <div>
      <div className={styles.header}>
        <Title className={styles.title}>{translate('crypto.assets.title')}</Title>

        <HeaderSearch
          coinsList={data?.lists?.coins ?? []}
          filters={filters}
          providersList={data?.lists?.providers ?? []}
          refetch={() => refetch()}
          setFilters={setFilters}
        />

        <HeaderTable columns={9}>
          <HeaderCell>{'Investment'}</HeaderCell>
          <HeaderCell>{'Total'}</HeaderCell>
          <HeaderCell>{'Profit'}</HeaderCell>
          <HeaderCell>{'Percentage'}</HeaderCell>
          <HeaderCell>{'Positive'}</HeaderCell>
          <HeaderCell>{'Negative'}</HeaderCell>
          <HeaderCell>{'Positive Count'}</HeaderCell>
          <HeaderCell>{'Negative Count'}</HeaderCell>
          <HeaderCell>{'Airdrop ITEMS'}</HeaderCell>

          <Row>
            <Cell center>{formatNumber(data?.invest, '$')}</Cell>
            <Cell center>{formatNumber(data?.total, '$')}</Cell>
            <Cell center>{formatNumber(data?.profit, '$')}</Cell>
            <Cell center>
              {new BigNumber(data?.total ?? 0)
                .dividedBy(data?.invest ?? 0)
                .multipliedBy(100)
                .minus(100)
                .toFixed(2) + '%'}
            </Cell>
            <Cell center>{formatNumber(data?.positive, '$')}</Cell>
            <Cell center>{formatNumber(data?.negative, '$')}</Cell>
            <Cell center>{data?.positiveCount}</Cell>
            <Cell center>{data?.negativeCount}</Cell>
            <Cell center>{formatNumber(data?.totalAirdrop, '$')}</Cell>
          </Row>
        </HeaderTable>

        <GridTable columns={9}>
          <HeaderRow>
            <HeaderCell sticky={65} width={100}>
              {'Coin'}
            </HeaderCell>

            <HeaderCell sticky={65}>{'Exchange'}</HeaderCell>
            <HeaderCell sticky={65}>{'Amount'}</HeaderCell>
            <HeaderCell sticky={65}>{'Total'}</HeaderCell>
            <HeaderCell sticky={65}>{'Unit Price'}</HeaderCell>
            <HeaderCell sticky={65}>{'Actual'}</HeaderCell>
            <HeaderCell sticky={65}>{'Total'}</HeaderCell>
            <HeaderCell sticky={65}>{'Profit'}</HeaderCell>
            <HeaderCell sticky={65}>{'Profit Dollar'}</HeaderCell>
          </HeaderRow>

          {(data?.coins ?? []).map((item, index) => {
            const isNegative = item.type === 'running' && new BigNumber(item.profitDollar).isLessThan(0);
            const isPositive = item.type === 'running' && new BigNumber(item.profitDollar).isGreaterThan(0);
            const code = [item.coin, item.provider.name].join('-');
            const tradeTransactions = item.transactions.filter(item => item.type === 'trade');
            const hasProfit = new BigNumber(item.transactionsStatus.tradeProfitDollar).isGreaterThan(0);

            return (
              <>
                <Row
                  isSelected={expandedCell.item === code}
                  key={index}
                  onClick={() => {
                    expandCell({
                      item: code,
                      visible: expandedCell.visible ? expandedCell.item !== code : true
                    });
                  }}
                >
                  <Cell isNegative={isNegative} isPositive={isPositive} type={item.type} width={220}>
                    <SpaceBetween>
                      <div>
                        <Icon src={item.icon} />

                        {item.coin}

                        {hasProfit ? (
                          <Tag color={'green'} style={{ marginLeft: 16 }}>
                            {'PROFIT'}
                          </Tag>
                        ) : (
                          ''
                        )}
                      </div>

                      <BadgeContainer>
                        {item.positiveTransactions > 0 && (
                          <Badge count={item.positiveTransactions} style={{ backgroundColor: '#52c41a' }} />
                        )}

                        <Badge
                          count={tradeTransactions.length}
                          style={{
                            backgroundColor: tradeTransactions.length === item.positiveTransactions ? '#52c41a' : '#999'
                          }}
                          title={'done'}
                        />

                        <Badge
                          count={item.transactions.length}
                          style={{
                            backgroundColor: '#e0e0e0'
                          }}
                          title={'done'}
                        />
                      </BadgeContainer>
                    </SpaceBetween>
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} type={item.type}>
                    {item.provider?.name}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {item.amount}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {formatNumber(item.totalInvest, '$')}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {formatNumber(item.unitPrice, '$')}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {formatNumber(item.actualPrice, '$')}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {formatNumber(item.actualTotal, '$')}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {item.profitPercentage === 'N/A'
                      ? 'N/A'
                      : item.profitPercentage === 'AIRDROP'
                      ? 'AIRDROP'
                      : new BigNumber(item.profitPercentage).toFixed(2) + '%'}
                  </Cell>

                  <Cell isNegative={isNegative} isPositive={isPositive} right type={item.type}>
                    {formatNumber(item.profitDollar, '$')}
                  </Cell>
                </Row>

                <Row disableHover>
                  <Cell collapse={9}>
                    <Expanded expanded={code == expandedCell.item && expandedCell.visible}>
                      <Content>
                        <TransactionsList
                          list={
                            data?.coins.find(item => [item.coin, item.provider.name].join('-') === expandedCell.item)
                              ?.transactions
                          }
                          refetch={() => refetch()}
                          setPause={setPause}
                          status={
                            data?.coins.find(item => [item.coin, item.provider.name].join('-') === expandedCell.item)
                              ?.transactionsStatus
                          }
                        />
                      </Content>
                    </Expanded>
                  </Cell>
                </Row>
              </>
            );
          })}
        </GridTable>
      </div>
    </div>
  );
}
