/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useGetTradesInvalidator } from './use-get-trades';

/**
 * Remove item.
 */

function removeItem(client: AxiosInstance, id: number): Promise<void> {
  return client.delete(resolveAppUrl('appsCryptoTradeDelete', { interpolations: { id: id } })).then(({ data }) => data);
}

/**
 * Export `useDeleteTrade`.
 */

export function useDeleteTrade() {
  const client = useRequestProvider();
  const invalidator = useGetTradesInvalidator();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await removeItem(client, id);

      await invalidator();

      return result;
    },
    onSuccess: () => {
      // Nothing...
    }
  });
}
