/**
 * Module dependencies.
 */

import { Button, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';
import { createAppsCryptoTransactionsTemplate } from '../../transactions/form-config';
import { resolveFormKeys } from 'app/components/organisms/crud/crud-edit';
import { CrudTemplateProvider } from 'app/components/organisms/crud/crud-template-provider';
import { CrudFormRender } from 'app/components/organisms/crud/crud-form';
import { useForm } from 'react-hook-form';
import { CrudEditType } from 'app/components/organisms/crud/form-types';
import { useCrudEdit } from 'app/hooks/requests/crud/use-crud-edit';
import { Title } from 'app/components/atoms/title/title';

/**
 * `Props` type.
 */

type Props = {
  item: Transaction;
  setPause: (value: boolean) => void;
  refetch: () => any;
};

/**
 * Export `EditAction` component.
 */

export function EditAction({ item, setPause, refetch }: Props): JSX.Element {
  const [translate] = useTranslation();
  const template = useMemo(() => createAppsCryptoTransactionsTemplate(translate), [translate]);
  const { mutate: edit } = useCrudEdit(template, { id: item.id });
  const { submitLabel, normalizePayload, normalizeInitialValues } = template.edit as CrudEditType;
  const [isOpen, setOpen] = useState<boolean>(false);
  const formFields = useMemo(() => {
    if (typeof template.edit?.formFields === 'function') {
      return template.edit?.formFields(item);
    }

    return template.edit?.formFields ?? [];
  }, [item, template.edit]);

  const formKeys = useMemo(() => {
    if (!template.edit?.formFields) {
      return [];
    }

    return resolveFormKeys(formFields);
  }, [formFields, template.edit?.formFields]);

  const form = useForm({
    values: normalizeInitialValues?.(formKeys, item),
    mode: 'all'
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  const onSubmit = async (values: any) => {
    await edit(await normalizePayload?.(values), {
      onSuccess: () => {
        refetch();
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    setPause(isOpen);
  }, [isOpen, setPause]);

  return (
    <>
      <Button icon={<EditOutlined />} onClick={() => setOpen(true)} size={'small'} />

      <Modal
        confirmLoading={isSubmitting}
        okText={submitLabel}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form.handleSubmit(onSubmit)();
        }}
        open={isOpen}
        width={800}
      >
        <Title>{'Edit transaction'}</Title>

        <CrudTemplateProvider template={template}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CrudFormRender form={form} formFields={formFields} formType={'edit'} />
          </form>
        </CrudTemplateProvider>
      </Modal>
    </>
  );
}
