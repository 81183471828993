/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Dashboard } from './dashboard';

/**
 * Export `SkinAppsCrypto` component.
 */

export function SkinAppsCrypto(): JSX.Element {
  const [translate] = useTranslation();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: '1',
          pathname: appRoutes.dashboard.appsCrypto.base,
          icon: <PieChartOutlined />,
          label: translate('appsCrypto.menu.overview')
        },
        {
          key: '2',
          pathname: appRoutes.dashboard.appsCrypto.transactions,
          icon: <PieChartOutlined />,
          label: translate('appsCrypto.menu.transactions')
        },
        {
          key: '3',
          pathname: appRoutes.dashboard.appsCrypto.trades,
          icon: <PieChartOutlined />,
          label: translate('appsCrypto.menu.trades')
        },
        {
          key: '4',
          pathname: appRoutes.dashboard.appsCrypto.providers,
          icon: <HiOutlineDocumentReport />,
          label: translate('appsCrypto.menu.providers')
        }
      ]),
    [translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
