/**
 * Module dependencies.
 */

import { RenderProps } from 'app/components/organisms/crud/form-widgets/render';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './stats.module.less';
import { useParams } from 'react-router-dom';
import { useProject } from 'app/hooks/requests/projects/use-project';

/**
 * Export `Stats` component.
 */

export function Stats({ form }: RenderProps): JSX.Element | null {
  const [translate] = useTranslation();
  const values = form.watch();
  const params = useParams();
  const { data, isLoading } = useProject(params.projectId);
  const { targetIvaAmount, total, iva } = useMemo(() => {
    if (!data) {
      return {};
    }

    const targetIvaAmount = new BigNumber(values.budget).multipliedBy(values.ivaRange).dividedBy(100);
    const iva = new BigNumber(targetIvaAmount).multipliedBy(values.iva ?? data.defaultIVA).dividedBy(100);
    const total = new BigNumber(values.budget).plus(iva);

    return {
      targetIvaAmount,
      iva,
      total
    };
  }, [data, values.budget, values.iva, values.ivaRange]);

  if (!values.budget || !total || isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.iva}>
        <div className={styles.title}>{'IVA'}</div>
        <Field title={translate('common.labels.baseValue')} value={formatCurrency(values.budget)} />

        <Field title={translate('common.labels.budgetWithIVA')} value={formatCurrency(targetIvaAmount)} />

        <Field title={translate('common.labels.ivaValue')} value={formatCurrency(iva)} />

        <Field title={translate('common.labels.totalBudget')} value={formatCurrency(total)} />
      </div>

      {values.prevision && (
        <div className={styles.estimate}>
          <div className={styles.title}>{'Estimate'}</div>
          <Field title={translate('common.labels.prevision')} value={formatCurrency(values.prevision)} />
          <Field title={translate('common.labels.budget')} value={formatCurrency(total)} />
          <Field
            title={translate('common.labels.saved')}
            value={formatCurrency(new BigNumber(values.prevision).minus(total))}
          />
        </div>
      )}
    </div>
  );
}
