/**
 * Module dependencies.
 */

import { menuConfig } from 'app/components/templates/dashboard/dashboard-header';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
};

/**
 * `CollapseMenu` type.
 */

type CollapseMenu = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

/**
 * `CollapseMenuContext` context.
 */

const CollapseMenuContext = createContext<CollapseMenu>({
  collapsed: false,
  setCollapsed: () => null
});

/**
 * Export `useCollapseMenuProvider`.
 */

export function useCollapseMenuProvider() {
  return useContext(CollapseMenuContext);
}

/**
 * Export `CollapseMenuProvider` component.
 */

export function CollapseMenuProvider({ children }: Props): JSX.Element {
  const [collapsed, setCollapsed] = useState(true);
  const currentLocation = useRef<string | undefined>(undefined);
  const loc = useLocation();
  const mainMenuPaths = useMemo(() => {
    return menuConfig.map(item => item.pathname);
  }, []);

  useEffect(() => {
    if (!loc.pathname && currentLocation.current === loc.pathname) {
      return;
    }

    if (currentLocation.current === undefined) {
      currentLocation.current = loc.pathname;

      return;
    }

    if (mainMenuPaths.includes(loc.pathname)) {
      return;
    }

    setCollapsed(false);
  }, [loc.pathname, mainMenuPaths]);

  return (
    <CollapseMenuContext.Provider
      value={{
        collapsed,
        setCollapsed
      }}
    >
      <div>{children}</div>
    </CollapseMenuContext.Provider>
  );
}
