/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useCryptoOverviewInvalidator } from './use-get-apps-crypto-overview';

/**
 * Remove item.
 */

function removeItem(client: AxiosInstance, id: number): Promise<void> {
  return client
    .delete(resolveAppUrl('appsCryptoDeleteTransaction', { interpolations: { id: id } }))
    .then(({ data }) => data);
}

/**
 * Export `useDeleteTransaction`.
 */

export function useDeleteTransaction() {
  const client = useRequestProvider();
  const invalidator = useCryptoOverviewInvalidator();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await removeItem(client, id);

      await invalidator();

      return result;
    }
  });
}
