/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import styled from 'styled-components';

/**
 * Export `Header` styled component.
 */

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

/**
 * Export `StyledTitle` styled component.
 */

export const StyledTitle = styled(Title)`
  padding: 0;
  margin: 0;
`;
