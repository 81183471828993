/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';
import { pick, reduce } from 'lodash';
import BigNumber from 'bignumber.js';
import { Stats } from './stats';
import { formatCurrency } from 'app/core/utils/formatter';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { ProjectProposal } from 'app/types/project';
import { TableFooter } from './table-footer';
import React from 'react';
import { PositioningWidget } from 'app/components/organisms/positioning-widget/positioning-widget';
import { RenderIva } from 'app/components/molecules/render-iva/render-iva';
import { MovementsButton } from './movements-button';
import { Name } from './components/name';

/**
 * Sum field.
 */

function sumField(list: any[], field: string) {
  return reduce(
    list,
    (acc: BigNumber, item: any) => {
      if (!item) {
        return acc;
      }

      return acc.plus(new BigNumber(item[field]));
    },
    new BigNumber('0')
  );
}

/**
 * Hide if empty.
 */

function hideIfEmpty(value: string, otherValue?: string | undefined) {
  if (value === '0.00 €' && (otherValue === undefined || otherValue === '0.00 €')) {
    return '';
  }

  return value;
}

/**
 * Style row without proposal.
 */

function styleRowWithoutProposal(values: any) {
  let backgroundColor = 'rgba(0, 0, 0, 0.1)';

  if (values.withoutProposal) {
    backgroundColor = 'rgba(0, 100, 0, 0.1)';
  }

  if (values.closedBudget) {
    backgroundColor = 'rgba(0, 100, 0, 0.3)';
  }

  return { backgroundColor, fontFamily: 'Oswald' };
}

/**
 * Check paid.
 */

function checkPaid(item: any) {
  const totalMovementTax = new BigNumber(item.totalMovementTax);
  const paid = new BigNumber(item.paid);
  const perc = new BigNumber(paid).multipliedBy(100).dividedBy(totalMovementTax);

  return {
    fontFamily: 'Oswald',
    backgroundColor: perc.toString() === '100' ? 'rgba(0, 100, 0, 0.3)' : 'rgba(100, 0, 0, 0.3)'
  };
}

/**
 * Have budget.
 */

function haveProposal(callback: (proposals: ProjectProposal[], item: any) => any) {
  return (item: any) => {
    if (item.withoutProposal) {
      return callback(
        [
          {
            id: -1,
            budget: item.prevision,
            ivaRange: '100',
            total: item.totalMovementTax
          }
        ],
        item
      );
    }

    if (item.proposals.length === 0) {
      return '';
    }

    return callback(item.proposals, item);
  };
}

/**
 * Movements row style.
 */

function movementsRowStyle(values: any) {
  let backgroundColor = 'rgba(0, 100, 0, 0.1)';

  if (values.closedBudget) {
    backgroundColor = 'rgba(0, 100, 0, 0.3)';
  }

  return { backgroundColor, fontFamily: 'Oswald' };
}

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    size: '1fr',
    style: { whiteSpace: 'nowrap' },
    key: 'name',
    render: item => {
      return <Name item={item} />;
    }
  },
  {
    title: translate('common.table.columns.prevision'),
    dataIndex: 'prevision',
    style: { justifyContent: 'flex-end' },
    styleRow: (values: any) => ({
      ...styleRowWithoutProposal(values),
      borderLeft: '2px solid rgba(0, 0, 0, 0.3)'
    }),
    key: 'prevision',
    render: item => formatCurrency(item.prevision)
  },
  {
    title: translate('common.table.columns.projectBudget'),
    dataIndex: 'budget',
    style: { justifyContent: 'flex-end' },
    styleRow: styleRowWithoutProposal,
    key: 'budget',
    render: haveProposal(proposals => {
      const total = sumField(proposals, 'total');

      return formatCurrency(total);
    })
  },
  {
    title: translate('common.table.columns.ivaPerc'),
    dataIndex: 'ivaPerc',
    style: { justifyContent: 'flex-end' },
    styleRow: styleRowWithoutProposal,
    key: 'ivaPerc',
    render: haveProposal(() => {
      return <RenderIva />;
    })
  },
  {
    title: translate('common.table.columns.budgetTotal'),
    dataIndex: 'budgetTotal',
    style: { justifyContent: 'flex-end' },
    styleRow: styleRowWithoutProposal,
    key: 'budgetTotal',
    render: haveProposal(proposals => {
      const total = sumField(proposals, 'total');

      return formatCurrency(total);
    })
  },
  {
    title: translate('common.table.columns.budgetDiff'),
    dataIndex: 'budgetDiff',
    style: { justifyContent: 'flex-end' },
    styleRow: styleRowWithoutProposal,
    key: 'diff',
    render: haveProposal((proposals, item) => {
      const total = sumField(proposals, 'total');

      return formatCurrency(new BigNumber(item.prevision).minus(total).toString());
    })
  },
  {
    title: translate('common.table.columns.movementTotal'),
    dataIndex: 'movementTotal',
    style: {
      justifyContent: 'flex-end',
      borderLeft: '2px solid rgba(0, 0, 0, 0.3)',
      minWidth: '100px'
    },
    styleRow: movementsRowStyle,
    key: 'movementTotal',
    render: item => hideIfEmpty(formatCurrency(new BigNumber(item.totalMovement).toFixed(2)))
  },
  {
    title: translate('common.table.columns.movementTotalTax'),
    dataIndex: 'movementTotalTax',
    style: { justifyContent: 'flex-end', minWidth: '100px' },
    styleRow: movementsRowStyle,
    key: 'movementTotalTax',
    render: item => hideIfEmpty(formatCurrency(new BigNumber(item.totalMovementTax).toFixed(2)))
  },
  {
    title: translate('common.table.columns.diffOverBudget'),
    dataIndex: 'diffOverBudget',
    style: { justifyContent: 'flex-end', minWidth: '100px' },
    styleRow: movementsRowStyle,
    key: 'diffOverBudget',
    render: item => hideIfEmpty(formatCurrency(new BigNumber(item.diffOverBudget).toFixed(2)))
  },
  {
    title: translate('common.table.columns.movementPaid'),
    dataIndex: 'movementPaid',
    style: {
      justifyContent: 'flex-end',
      borderLeft: '2px solid rgba(0, 0, 0, 0.3)',
      minWidth: '100px'
    },
    styleRow: checkPaid,
    key: 'movementPaid',
    render: item => hideIfEmpty(formatCurrency(new BigNumber(item.paid).toFixed(2)), formatCurrency(item.debt))
  },
  {
    title: translate('common.table.columns.movementDebt'),
    dataIndex: 'movementDebt',
    style: { justifyContent: 'flex-end', minWidth: '100px' },
    styleRow: checkPaid,
    key: 'movementDebt',
    render: item => hideIfEmpty(formatCurrency(new BigNumber(item.debt).toFixed(2)), formatCurrency(item.paid))
  },
  {
    title: translate('common.table.columns.empty'),
    dataIndex: 'movementDebt',
    style: {
      justifyContent: 'flex-end',
      borderRight: '2px solid rgba(0, 0, 0, 0.3)',
      minWidth: '100px'
    },
    styleRow: checkPaid,
    key: 'percentage',
    render: item => {
      const totalMovementTax = new BigNumber(item.totalMovementTax);
      const paid = new BigNumber(item.paid);
      const perc = new BigNumber(paid).multipliedBy(100).dividedBy(totalMovementTax);

      return totalMovementTax.isNaN() ? null : `${perc.toFixed(2).toString()}%`;
    }
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction, type: 'add' | 'edit'): FormList => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 5,
        children: [
          {
            type: 'inputField',
            name: 'name',
            label: translate('common.labels.name'),
            rules: requiredRule(translate)
          },
          {
            type: 'inputNumberField',
            name: 'prevision',
            label: translate('common.labels.prevision'),
            rules: requiredRule(translate)
          },
          {
            type: 'checkBoxField',
            name: 'withoutProposal',
            label: translate('common.labels.withoutProposal'),
            rules: undefined
          },
          {
            type: 'selectField',
            name: 'proposalIds',
            mode: 'multiple',
            visible: values => {
              return type === 'edit' && !values.withoutProposal;
            },
            label: translate('common.labels.proposal'),
            rules: undefined,
            allowClear: true,
            options: {
              hook: useCrudRequest as any,
              hookProps: (values: any, params: any) => {
                return [
                  {
                    key: ['supplier', params.projectId],
                    options: {
                      interpolations: params,
                      params: { filters: { budgetId: values?.id } }
                    },
                    endpoint: apiEndpoints.projectProposals
                  }
                ];
              },
              normalize: (items: ProjectProposal[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    label: `${item.id} - ${item.supplier?.company}: ${formatCurrency(item.total)}`
                  })) ?? []
                );
              }
            }
          },
          {
            type: 'checkBoxField',
            name: 'closedBudget',
            label: translate('common.labels.closedBudget'),
            rules: undefined
          }
        ]
      },
      {
        column: 12,
        children: [
          {
            type: 'render',
            name: 'stats',
            renderComponent: Stats
          }
        ]
      }
    ]
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    autocomplete: 'false',
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate, 'add'),
  normalizeInitialValues: (formkeys, values: any) => {
    return values;
  },
  normalizePayload: (values: any) => ({
    ...values,
    closedBudget: !!values?.closedBudget,
    withoutProposal: !!values?.withoutProposal,
    prevision: new BigNumber(values.prevision).toString()
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate, 'edit'),
  normalizeInitialValues: (formkeys, values: any) => {
    const data = {
      ...values,
      closedBudget: !!values?.closedBudget,
      withoutProposal: !!values?.withoutProposal,
      proposalIds: values?.proposals?.map((item: any) => item.id) ?? []
    };

    return data;
  },
  normalizePayload: (values: any) => {
    return {
      ...pick(values, ['id', 'name', 'proposalIds']),
      closedBudget: !!values?.closedBudget,
      withoutProposal: !!values?.withoutProposal,
      prevision: new BigNumber(values.prevision).toString()
    };
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction, extendedProposalsTemplate: CrudTemplate): CrudListType => ({
  append: {
    order: [{ key: 'positioning', direction: 'asc' }]
  },
  columns: listColumns(translate),
  style: { width: '100%' },
  route: appRoutes.dashboard.projects.project.budgets,
  key: ['projectBudgets'],
  expandedTemplate: extendedProposalsTemplate,
  endpoint: apiEndpoints.projectBudgets,
  filters: listFilters(translate),
  renderActions: (item: any, template: CrudTemplate, refetch) => {
    return (
      <>
        <PositioningWidget item={item} refetch={refetch} template={template} />

        <MovementsButton item={item} />
      </>
    );
  },
  renderLastLine: props => <TableFooter {...props} />
});

/**
 * Config.
 */

export function createProjectBudgetsTemplate(
  translate: TFunction,
  extendedProposalsTemplate: CrudTemplate
): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate, extendedProposalsTemplate),
    remove: remove()
  });
}
