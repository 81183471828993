/**
 * Module dependencies.
 */

import React from 'react';
import { Cell, GridTable, HeaderCell, Row } from '../overview.styles';
import { RemoveAction } from './remove-action';
import { EditAction } from './edit-action';
import moment from 'moment';
import { Transaction, TransactionStatus } from 'app/hooks/requests/apps-crypto/use-get-apps-crypto-overview';
import BigNumber from 'bignumber.js';
import { formatNumber } from '../../transactions/form-config';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  list: Transaction[] | undefined;
  refetch: () => void;
  setPause: (pause: boolean) => void;
  status: TransactionStatus | undefined;
};

/**
 * `Badge` styled component.
 */

const Badge = styled.div`
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

/**
 * `Title` styled component.
 */

const Title = styled.div`
  color: #333;
  font-weight: 700;
`;

/**
 * `Value` styled component.
 */

const Value = styled.div``;

/**
 * Export `TransactionsList` component.
 */

export function TransactionsList({ list, refetch, status, setPause }: Props): JSX.Element {
  return (
    <GridTable columns={12}>
      <HeaderCell>{'ID'}</HeaderCell>
      <HeaderCell>{'Amount'}</HeaderCell>
      <HeaderCell>{'Total'}</HeaderCell>
      <HeaderCell>{'Unit Price'}</HeaderCell>
      <HeaderCell width={50}>{'Type'}</HeaderCell>
      <HeaderCell>{'Sell Price'}</HeaderCell>
      <HeaderCell>{'Current Total'}</HeaderCell>
      <HeaderCell>{'Profit %'}</HeaderCell>
      <HeaderCell>{'Profit $'}</HeaderCell>
      <HeaderCell>{'Created At'}</HeaderCell>
      <HeaderCell>{'Updated At'}</HeaderCell>
      <HeaderCell>{'Actions'}</HeaderCell>

      {(list ?? []).map((transaction, index) => {
        const isNegative = transaction.type === 'trade' && new BigNumber(transaction.currentProfit).isLessThan(0);
        const isPositive = transaction.type === 'trade' && new BigNumber(transaction.currentProfit).isGreaterThan(0);

        return (
          <Row disableHover key={index}>
            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              type={transaction.currentType}
            >
              {transaction.id}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {transaction.amount}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {formatNumber(transaction.total, '$')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {formatNumber(transaction.unitPrice, '$')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {transaction.currentType === 'running' ? transaction.type : transaction.currentType}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {formatNumber(transaction.closedUnitPrice, '$')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {formatNumber(transaction.currentTotal, '$')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {transaction.currentProfitPercentage}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {formatNumber(transaction.currentProfit, '$')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              {moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Cell>

            <Cell
              closed={transaction.closedBy?.length > 0 || transaction.closedTo?.length > 0}
              isNegative={isNegative}
              isPositive={isPositive}
              right
              type={transaction.currentType}
            >
              <RemoveAction item={transaction} refetch={refetch} setPause={setPause} />

              <EditAction item={transaction} refetch={refetch} setPause={setPause} />
            </Cell>
          </Row>
        );
      })}

      <Row disableHover>
        <Cell right span={12} style={{ display: 'flex', gap: 16 }} summary>
          <Badge>
            <Title>{'Realized Profit'}</Title>

            <Value>{formatNumber(status?.realizedProfit, '$')}</Value>
          </Badge>

          <Badge>
            <Title>{'Total'}</Title>

            <Value>{formatNumber(status?.total, '$')}</Value>
          </Badge>

          <Badge>
            <Title>{'Invest Without Profit'}</Title>

            <Value>
              {formatNumber(new BigNumber(status?.total ?? '0').plus(status?.realizedProfit ?? '0').toString(), '$')}
            </Value>
          </Badge>

          <Badge>
            <Title>{'Trade Invest'}</Title>

            <Value>{formatNumber(status?.tradeInvest, '$')}</Value>
          </Badge>

          <Badge>
            <Title>{'Trade Current'}</Title>

            <Value>{formatNumber(status?.tradeCurrent, '$')}</Value>
          </Badge>

          <Badge>
            <Title>{'Trade Profit %'}</Title>

            <Value>{new BigNumber(status?.tradeProfitPercentage ?? '0').toFixed(2) + '%'}</Value>
          </Badge>

          <Badge>
            <Title>{'Trade Profit (Dollar)'}</Title>

            <Value>{formatNumber(status?.tradeProfitDollar, '$')}</Value>
          </Badge>
        </Cell>
      </Row>
    </GridTable>
  );
}
