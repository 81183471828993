/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Transaction } from './use-get-apps-crypto-overview';

/**
 * Get transactions.
 */

function getTransactions(client: AxiosInstance, search: string): Promise<Transaction[]> {
  return client({
    method: 'GET',
    url: resolveAppUrl('appsCryptoTransactions'),
    params: {
      filters: {
        search
      },
      order: [
        {
          key: 'createdAt',
          direction: 'desc'
        }
      ]
    }
  }).then(({ data }) => data?.data);
}

/**
 * Export `useGetTransactionsInvalidator`.
 */

export function useGetTransactionsInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['getTransactions']);
  }, [queryClient]);
}

/**
 * Export `useGetTransactions`.
 */

export function useGetTransactions(search: string) {
  const client = useRequestProvider();

  return useQuery(['getTransactions', search], {
    queryFn: async () => {
      return await getTransactions(client, search);
    }
  });
}
