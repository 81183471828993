/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Content, SideMenu, Wrapper } from './dashboard.styles';
import { MenuItem } from 'app/types/navigation';
import { resolveSelectedKeys } from './dashboard-header';
import { ItemMenu } from './item-menu';
import { useCollapseMenuProvider } from 'app/components/providers/collapse-menu-provider/collapse-menu-provider';

/**
 * `Props` type.
 */

type Props = {
  menu: MenuItem[];
  children: JSX.Element;
};

/**
 * Export `Dashboard` component.
 */

export function Dashboard({ menu, children }: Props): JSX.Element {
  const { pathname } = useLocation();
  const { collapsed } = useCollapseMenuProvider();
  const selectedItem = useMemo(() => resolveSelectedKeys(pathname, menu), [menu, pathname]);

  return (
    <Content>
      <SideMenu isVisible={collapsed}>
        {(menu ?? []).map(item => (
          <ItemMenu item={item} key={item.key} selectedItem={selectedItem} />
        ))}
      </SideMenu>

      <Wrapper isVisible={collapsed}>{children}</Wrapper>
    </Content>
  );
}
