/**
 * Module dependencies.
 */

import { styled } from 'styled-components';

/**
 * Export `Icon` styled component.
 */

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;
